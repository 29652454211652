@import "styles/mixins.scss";
@import "styles/variables.scss";

.pagination {
  align-items: center;
  display: flex;
  font-size: 18px;
  line-height: 1;
  justify-content: center;
  margin-bottom: 50px;
  overflow: hidden;

  p {
    font-family: "Beatrice-Bold", serif;
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    margin-top: 8px;
  }

  &__dark {
    p {
      color: $white;
      opacity: 0.3;
    }
  }

  &__item {
    border-bottom: 5px solid transparent;
    color: $grey;
    font-family: "Beatrice-Bold", serif;
    margin: 0 13px;
    padding: 3px 5px 5px;
    transition: color 200ms ease, border-bottom 200ms ease;

    @include respond-below(450px) {
      margin: 0 7px;
      padding: 3px 4px 5px;
    }

    &__active {
      pointer-events: none;
    }

    &__active,
    &:hover {
      border-bottom: 5px solid $terracotta;
      color: $forest;
    }

    &__dark {
      &__active,
      &:hover {
        color: $white;
      }
    }
  }

  &__arrow {
    border-bottom: 5px solid transparent;
    color: $peach;
    font-size: 24px;
    line-height: 18px;
    margin-top: 5px;
    padding: 3px 5px 10px;
    transition: color 200ms ease, border-bottom 200ms ease;

    svg {
      stroke: $peach;
    }

    &:hover {
      border-bottom: 5px solid $terracotta;
      color: $forest;
      svg {
        stroke: $forest;
      }
    }

    &__dark {
      &:hover {
        color: $sand;
        svg {
          stroke: $sand;
        }
      }
    }

    &__disabled {
      color: $sand;
      opacity: 0.3;

      svg {
        stroke: $sand;
      }
    }
  }
}
