@import "styles/mixins.scss";
@import "styles/variables.scss";

$navRowColumnBreak: $break-xsmall;

.editAccount {
  @include gutter;
  @include header-top-padding;
  @include layout();
  display: flex;
  padding-bottom: 80px;

  @include respond-below($navRowColumnBreak) {
    flex-direction: column;
  }

  &__nav {
    @include respond($navRowColumnBreak) {
      display: block;
      flex-basis: 35%;
      height: 100%;
      padding-right: 60px;
      padding-top: 20px;
      position: sticky;
      top: 50px;
      width: 100%;

      &:after {
        // FROGS: url imports node-sass
        // content: url("icons/pencil-scribble.png");
        position: absolute;
        right: 50px;
        top: -50px;
      }
    }

    @include respond($break-medium) {
      margin-right: 0;
    }

    &__item {
      border-left: 5px solid transparent;
      cursor: pointer;
      display: block;
      font-size: 18px;
      font-weight: 400;
      line-height: 23px;
      margin-bottom: 35px;
      padding-left: 10px;
      transition: border 200ms ease, font-weight 200ms ease;

      @include respond-below($navRowColumnBreak) {
        align-items: center;
        font-size: 14px;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 5px;
        padding-right: 5px;
      }

      &__active {
        border-left: 5px solid $terracotta;
        font-family: "Beatrice-Bold", sans-serif;
      }
    }

    &__illo {
      width: 200px;

      img {
        width: 100%;
      }

      @include respond-below($navRowColumnBreak) {
        display: none;
      }
    }
  }

  &__body {
    flex-basis: 65%;
    padding-top: 20px;

    @include respond($navRowColumnBreak) {
      padding-left: 40px;
    }

    h2 {
      @include header-two;
      padding-bottom: 40px;
    }

    h3 {
      @include header-three;
      padding-bottom: 30px;
    }

    h4 {
      @include body-one;
      font-weight: normal;
      padding-bottom: 50px;
    }

    .form + .form {
      border-top: 1px solid $forestTransparent;
      margin-top: 36px;
      padding-top: 60px;
    }

    .form button {
      position: relative;
      top: -26px;
    }

    section {
      margin: 0;
      padding-bottom: 40px;
      max-width: 100%;
    }

    &__imageUpload {
      max-width: 330px;
      padding-bottom: 30px;

      h3 {
        padding-bottom: 0;
      }
    }

    &__text {
      @include body-small;

      a {
        color: $forest;
        font-family: "Beatrice-Bold", serif;
        text-decoration: underline;
      }
    }

    &__legal {
      p {
        @include body-one;
      }
    }
  }

  &__locations {
    display: grid;
    grid-auto-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 30px;

    @include respond(600px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.hideSmall {
  @include respond-below($break-mobile) {
    display: none;
  }
}

.stripeForm {
  button {
    margin-top: 40px;
  }
}
