@import "styles/mixins.scss";
@import "styles/variables.scss";

.eventcard {
  align-items: center;
  border-top: 1px solid $lightgrey;
  color: $forest;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 25px 0 40px;
  position: relative;

  @include respond() {
    flex-wrap: nowrap;
  }

  &__isCancelled {
    h4 {
      text-decoration: line-through;
    }
  }

  &__cancelledFlag {
    left: 0;
    position: absolute;
    top: -25px;

    @include respond() {
      left: -20px;
    }
  }

  &__host {
    padding-right: 40px;
    text-align: center;

    &__image {
      background-color: $peach;
      background-position: center;
      background-size: cover;
      border: 2px solid $peach;
      border-radius: 50%;
      height: 50px;
      margin: auto;
      position: relative;
      width: 50px;

      @include respond($tablet) {
        height: 75px;
        width: 75px;
      }
    }

    &__roletitle {
      position: absolute;
      right: -20px;
      top: -30px;

      @include respond($tablet) {
        right: -15px;
        top: -30px;
      }
    }

    &__checkmark {
      position: absolute;
      left: -6px;
      bottom: -6px;
    }

    h4 {
      color: $forest;
      font-family: "Beatrice-Bold", serif;
      font-size: 13px;
      line-height: 18px;
      margin-top: -1px;

      @include respond($tablet) {
        margin-top: 10px;
      }
    }
  }

  &__rsvp {
    border-left: 1px solid $lightgrey;
    flex-basis: 195px;
    padding-left: 25px;
    width: 195px;
    flex-shrink: 0;

    button {
      min-width: 170px;
    }

    p {
      color: $darkgrey;
      margin-top: 10px;
    }

    &__going {
      @include body-one;
      color: $forest;
    }
  }

  &__attend {
    &__info {
      color: $darkgrey;
    }
  }

  &__date {
    flex-shrink: 0;
    text-align: center;
    flex-basis: 60px;

    @include respond-below() {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    @include respond($mobile) {
      flex-basis: 65px;
    }

    @include respond() {
      flex-basis: 85px;
    }

    p {
      @include header-five;
      color: $terracotta;

      span {
        @include number;

        @include respond() {
          line-height: 66px;
        }
      }
    }

    &__icon {
      flex-shrink: 0;
      padding-top: 4px;
      width: 60px;

      @include respond-below(850px) {
        padding-bottom: 10px;
        margin-left: 30px;
      }

      @include respond($mobile) {
        width: 65px;
      }

      @include respond() {
        width: 85px;
      }
    }
  }

  &__info {
    @include respond(850px) {
      flex-basis: 665px;
      padding-left: 35px;
      padding-right: 35px;
    }

    h3 {
      @include header-five;
      color: $grey;
      margin-bottom: 10px;
      padding-right: 35px;
    }

    h4 {
      @include header-three;
      margin-bottom: 5px;
      padding-right: 35px;

      @include respond(850px) {
        padding-right: 0;
      }
    }

    p {
      @include body-caption;
    }
  }
}

.eventcardMini {
  background-color: $white;
  border: 2px solid $peach;
  color: $forest;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  max-width: calc(100vw - 50px);
  position: relative;
  text-align: left;

  &__image {
    background-size: cover;
    background-position: center;
    border-right: 2px solid $peach;
    flex-basis: 176px;
    flex-shrink: 0;

    @include respond-below(600px) {
      display: none;
    }
  }

  &__virtual {
    @include regular-label;
    background-color: $peach;
    color: $forest;
    border-radius: 6px 6px 6px 0px;
    padding: 3px 6px;
    position: absolute;
    top: -9px;
    left: 0;
  }

  &__info {
    background-color: $white;
    // border: 2px solid $peach;
    color: $forest;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    text-align: left;
  }

  &__host {
    position: absolute;
    right: 10px;
    text-align: center;
    top: -27px;

    @include respond() {
      right: 20px;
    }

    img {
      background-color: $peach;
      border: 2px solid $peach;
      border-radius: 50%;
      height: 50px;
      margin-bottom: 1px;
      width: 50px;
    }

    h4 {
      @include header-five;
      letter-spacing: 0;
      text-transform: none;
    }
  }

  &__top {
    border-bottom: 1px solid $forestTransparent;
    display: flex;
    padding-bottom: 15px;

    &__date {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      justify-content: flex-start;
      padding-top: 1px;
      text-align: center;
      width: 65px;

      @include respond() {
        width: 70px;
      }

      p {
        @include header-five;
        color: $terracotta;
        font-family: "Beatrice-ExtraBold", serif;

        span {
          @include number;
          font-size: 36px;
          padding-top: 10px;

          @include respond() {
            font-size: 36px;
          }
        }
      }
    }

    &__icon {
      padding-left: 2px;

      img {
        width: 36px;
      }
    }

    &__info {
      h3 {
        @include header-five;
        color: $grey;
        padding-bottom: 8px;
        padding-right: 27px;
      }

      h4 {
        @include header-four;
        padding-bottom: 10px;
        padding-right: 25px;
      }

      p {
        @include body-caption;
        margin-bottom: 3px;
      }
    }
  }

  &__bottom {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 15px;

    p {
      @include body-caption;
      color: $grey;
      margin-bottom: 3px;
    }
  }
}
.virtualFlag {
  @include regular-label;
  background-color: $peach;
  color: $forest;
  border-radius: 6px;
  padding: 1px 6px;
}
