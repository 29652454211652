@import "styles/variables.scss";

:global {
  .dp-modal .dp {
    animation: none !important;
  }

  div {
    .dp:before {
      background: $peach;
      background: linear-gradient(-90deg, $sun 0%, $peach 100%);
    }

    .dp-clear {
      display: none;
    }

    .dp-day:hover,
    .dp-month:hover,
    .dp-year:hover,
    .dp-current:focus,
    .dp-current,
    .dp-day:focus,
    .dp-month:focus,
    .dp-year:focus,
    .dp-close:active,
    .dp-clear:active,
    .dp-today:active,
    .dp-next:active,
    .dp-prev:active,
    .dp-cal-month:active,
    .dp-cal-year:active {
      background: $peach;
    }

    .dp-selected:hover,
    .dp-selected:focus,
    .dp-selected {
      background: $sun;
    }

    .dp-day-today:after {
      border-top-color: $succulent;
      border-right-color: $succulent;
    }

    @media screen and (min-device-width: 1200px) {
      .dp-close:hover,
      .dp-close:focus,
      .dp-clear:hover,
      .dp-clear:focus,
      .dp-today:hover,
      .dp-today:focus,
      .dp-next:hover,
      .dp-next:focus,
      .dp-prev:hover,
      .dp-prev:focus,
      .dp-cal-month:focus,
      .dp-cal-month:hover,
      .dp-cal-year:hover,
      .dp-cal-year:focus {
        background: $peach;
      }
    }
  }
}
