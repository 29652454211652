@import "styles/mixins.scss";
@import "styles/variables.scss";

.imageUpload {
  padding: 0 0 20px 0;

  h2 {
    font-size: 18px;
    margin-bottom: 0;
  }

  &__photo {
    background-color: $peach;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    height: 250px;
    margin: 30px auto;
    max-width: 250px;
    overflow: hidden;
    position: relative;

    canvas {
      border-radius: 50%;
      height: 250px;
      width: 250px;
    }

    &__event {
      border-radius: 0;
      height: 330px;

      canvas {
        border-radius: 0;
        height: auto;
        width: 250px;
      }
    }
  }

  p {
    @include header-four;
    color: $peach;
    cursor: pointer;
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__captured {
    background-color: transparent;

    canvas {
      position: relative;
      z-index: 100;
    }
  }

  video {
    display: none;
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: auto;
  }

  &__videoShow {
    video {
      display: block;
    }
  }

  &__button {
    align-items: center;
    background: $forest;
    border: 2px solid $forest;
    color: $peach;
    cursor: pointer;
    display: inline-flex;
    font-family: "Beatrice-Bold", serif;
    font-size: 18px;
    justify-content: center;
    line-height: 1;
    margin-bottom: 10px;
    min-width: 220px;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    transition: border 200ms ease, background 200ms ease, color 200ms ease;
    width: 100%;

    svg {
      height: 20px;
      margin-right: 8px;

      line,
      path {
        stroke: $peach;
        transition: stroke 200ms ease;
      }
    }

    &:hover {
      background: $peach;
      border: 2px solid rgba(245, 195, 143, 1);
      color: $forest;

      svg line,
      svg path {
        stroke: $forest;
      }
    }

    &__active {
      background-color: $forest;
      color: $peach;

      &:hover {
        background-color: $peach;
        color: $forest;
      }
    }
  }
}
