@import "styles/mixins.scss";
@import "styles/variables.scss";

.note {
  background-color: $white;
  border-radius: 15px;
  padding: 25px 20px;
  text-align: left;

  p {
    @include body-small;
    margin-bottom: 0;
  }
}
