
.loading {
  align-items: center;
  background-color: rgba(255,255,255,0.6);
  display: flex;
  height: 100vh;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 1000;
}