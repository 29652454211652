@import "styles/mixins.scss";
@import "styles/variables.scss";

.footer {
  background-color: $forest;
  text-align: center;

  &__top {
    @include gutter;
    @include layout();
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 60px;
    padding-top: 80px;
    text-align: left;

    @include respond() {
      flex-direction: row;
    }
  }

  &__row {
    flex-basis: 33%;
    padding-bottom: 20px;

    h3 {
      @include header-five;
      color: $peach;
      letter-spacing: 2px;
      margin-bottom: 15px;
      text-transform: uppercase;
    }
  }

  &__link {
    margin: 0;

    a {
      @include body-one;
      color: $nearwhite;
    }
  }

  &__social {
    padding-top: 30px;
    a {
      margin-right: 25px;

      svg path {
        fill: $nearwhite;
        transition: fill 200ms ease;
      }

      &:hover {
        svg path {
          fill: $peach;
        }
      }
    }
  }

  &__subtitle {
    @include body-large;
    color: $nearwhite;
    max-width: 340px;
    padding-bottom: 30px;
  }

  &__logo {
    width: 250px;
  }

  &__bottom {
    border-top: 2px solid $peach;
    color: $peach;
    padding: 20px;
    text-align: center;

    p {
      @include header-five;
      margin: 0;
    }
  }
}
