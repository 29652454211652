@import "styles/mixins.scss";
@import "styles/variables.scss";

.dashboard {
  &__header {
    background-color: $forest;
    color: $peach;
    // min-height: 40vw;
    padding-bottom: 40px;
    padding-top: 80px;
    position: relative;
    width: 100%;

    @include respond(900px) {
      // min-height: 400px;
      padding-bottom: 70px;
      padding-top: 160px;
    }

    @include respond-below(900px) {
      align-items: center;
      display: flex;
      justify-content: center;
      overflow: hidden;
    }

    &__wrapper {
      @include gutter();
      @include layout();
      position: relative;
      text-align: center;

      h1 {
        @include header-zero;
        font-family: "BeatriceDisplay-ExtraBold", serif;
        position: relative;
        z-index: 1;
      }
    }

    &__illo {
      display: none;
      opacity: 0.4;
      position: absolute;
      right: -30px;
      bottom: -180px;

      img {
        height: 180px;
        width: auto;
      }

      @include respond(900px) {
        display: block;
        opacity: 1;
        right: 0;
      }
    }
  }

  &__siteEvents {
    @include gutter();
    @include layout();
    padding-bottom: 70px;
    padding-top: 45px;

    h2 {
      @include header-two;
      padding-bottom: 25px;
    }

    &__buttons {
      align-items: center;
      border-top: 1px solid $lightgrey;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 40px;

      > div:first-of-type {
        margin-bottom: 20px;
      }

      @include respond() {
        flex-direction: row;

        > div:first-of-type {
          margin-right: 20px;
          margin-bottom: 0;
        }
      }
    }
  }

  &__morningtea {
    background-color: $peach;
    margin: 0;
    padding-bottom: 60px;
    padding-top: 50px;

    &__wrapper {
      @include gutter;
      @include layout;
      position: relative;
    }

    &__header {
      position: relative;
      h2 {
        @include header-two;
        margin-bottom: 15px;
      }

      p {
        @include body-small;
        margin-bottom: 30px;
      }

      &__icon {
        bottom: -10px;
        position: absolute;
        right: 0;

        @include respond-below() {
          display: none;
        }
      }
    }

    &__items {
      border-bottom: 1px solid $white;
      border-top: 1px solid $white;
      padding: 25px 0 0;
      display: flex;
      flex-direction: column;

      @include respond() {
        flex-direction: row;
        padding: 25px 0;
      }

      &__main {
        border-bottom: 1px solid $white;
        color: $forest;
        padding-bottom: 25px;
        padding-right: 10px;
        position: relative;
        transition: color 150ms ease;

        img {
          transform: rotate(0deg);
          transition: transform 220ms ease;
        }

        &:hover {
          color: $sand;

          img {
            transform: rotate(30deg);
          }
        }

        @include respond() {
          border-bottom: none;
          flex-basis: 33%;
          padding-bottom: 0;
        }

        h3 {
          @include header-two;
          margin-bottom: 20px;
        }

        &__icon {
          bottom: 10px;
          position: absolute;
          right: 0;

          @include respond() {
            bottom: 0;
            right: 25px;
          }
        }
      }

      &__otherItems {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @include respond() {
          border-left: 1px solid $white;
          flex-basis: 66%;
          flex-direction: row;
        }
      }
    }

    &__item {
      border-bottom: 1px solid $white;
      color: $forest;
      flex-basis: calc(50% - 61px);
      padding: 20px 5px;
      position: relative;
      margin: 0;
      transition: color 150ms ease;

      &:hover {
        color: $sand;
      }

      &:last-of-type {
        border-bottom: none;
      }

      @include respond() {
        border-bottom: none;
        padding: 5px 10px 15px;
        margin: 10px 30px;

        &:nth-of-type(2n + 1) {
          &:after {
            background-color: #ffffff;
            content: "";
            position: absolute;
            right: -30px;
            width: 1px;
            height: 120%;
            top: -10px;
          }
        }

        &:nth-of-type(1),
        &:nth-of-type(2) {
          border-bottom: 1px solid $white;
        }
      }

      h3 {
        @include header-three;
        margin-bottom: 20px;
      }
    }

    &__button {
      margin-top: 40px;
      text-align: center;
    }
  }

  &__latest {
    margin: 0;
    padding-top: 50px;

    &__wrapper {
      @include gutter;
      @include layout;
      border-bottom: 1px solid $lightgrey;
      padding-bottom: 60px;
      position: relative;
    }

    &__header {
      position: relative;

      @include respond-below(900px) {
        text-align: center;
      }

      h2 {
        @include header-two;
        margin-bottom: 15px;
        position: relative;
        z-index: 1;
      }

      p {
        @include body-small;
        margin-bottom: 30px;
        position: relative;
        z-index: 1;
      }

      &__icon {
        bottom: -50px;
        position: absolute;
        right: 0;

        @include respond-below(900px) {
          display: none;
        }
      }
    }

    &__items {
      padding: 25px 0 0;
      display: flex;
      flex-direction: column;
      width: 100%;

      @include respond(900px) {
        flex-direction: row;
        padding: 25px 0;
      }
    }

    &__item {
      border-right: 1px solid $lightgrey;
      display: block;
      padding: 0 20px;
      text-align: center;
      width: 100%;

      @include respond-below(900px) {
        border: none;
        border-bottom: 1px solid $lightgrey;
        margin: 0 auto 30px;
        max-width: 325px;
      }

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        border-right: none;
        padding-right: 0;
      }

      span {
        background-size: cover;
        display: block;
        height: 215px;
        width: 100%;
      }

      p {
        @include regular-label;
        color: $grey;
        padding-top: 20px;
      }

      h3 {
        @include header-three;
        margin-bottom: 20px;
      }
    }

    &__button {
      margin-top: 40px;
      text-align: center;
    }
  }

  &__guidelines {
    @include layout(1120px);
    border: 2px solid $forest;
    display: flex;
    flex-direction: column;

    @include respond() {
      flex-direction: row;
    }

    &__wrapper {
      @include gutter;
      padding-bottom: 130px;
      padding-top: 70px;

      @include respond() {
        padding-bottom: 130px;
        padding-top: 70px;
      }
    }

    &__title {
      align-items: center;
      background-color: $peach;
      border-bottom: 2px solid $forest;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 50px 10px;
      text-align: center;

      @include respond() {
        border-bottom: none;
        border-right: 2px solid $forest;
        flex-basis: 33%;
      }

      h2 {
        @include header-two;
      }
      p {
        @include body-small;
        @include respond() {
          max-width: 165px;
        }
      }
      a {
        font-weight: bolder;
        font-size: 15px;
        line-height: 22px;
        text-decoration-line: underline;
      }
    }

    &__cards {
      display: grid;
      flex-basis: 66%;
      grid-gap: 10px;
      grid-column-gap: 40px;
      grid-template-columns: repeat(1, 1fr);
      padding: 30px 20px;

      @include respond(600px) {
        grid-template-columns: repeat(2, 1fr);
        padding: 40px 30px;
      }
    }

    &__card {
      padding-bottom: 20px;
      h3 {
        @include header-three;
        margin-bottom: 10px;
      }

      p {
        @include body-small;
        margin-bottom: 0;
      }
    }
  }

  &__newsletters {
    margin: 0;
    padding-top: 60px;

    &__wrapper {
      @include gutter;
      @include layout;
      position: relative;
    }

    &__header {
      position: relative;

      @include respond-below(900px) {
        text-align: center;
      }

      h2 {
        @include header-two;
        margin-bottom: 15px;
        position: relative;
        z-index: 1;
      }

      p {
        @include body-small;
        margin-bottom: 30px;
        position: relative;
        z-index: 1;
      }

      &__icon {
        bottom: -50px;
        position: absolute;
        right: 0;
        width: 290px;

        @include respond-below(900px) {
          display: none;
        }
      }
    }

    &__button {
      margin-top: 60px;
      text-align: center;
    }

    &__flag {
      @include regular-label;
      background-color: $terracotta;
      color: $white;
      border-radius: 4px;
      padding: 3px 8px;
      position: absolute;
      top: -10px;
    }

    &__items {
      border-top: 1px solid $lightgrey;
      margin-top: 20px;
      position: relative;
    }

    &__item {
      border-bottom: 1px solid $lightgrey;
      display: flex;
      justify-content: space-between;
      padding: 30px 0;

      h3 {
        @include header-three;
        color: $forest;
        margin: 0;
        transition: color 200ms ease;
      }

      &__date {
        border-left: 1px solid $lightgrey;
        padding-left: 30px;
      }

      &:hover {
        h3 {
          color: $terracotta;
        }
      }
    }
  }
  &__picks {
    margin: 0;
    padding-bottom: 60px;
    padding-top: 60px;

    &__wrapper {
      @include gutter;
      @include layout;
      position: relative;
    }

    &__header {
      position: relative;

      @include respond-below(900px) {
        text-align: center;
      }

      h2 {
        @include header-two;
        margin-bottom: 15px;
        position: relative;
        z-index: 1;
      }

      p {
        @include body-small;
        margin-bottom: 30px;
        position: relative;
        z-index: 1;
      }

      &__icon {
        bottom: -50px;
        position: absolute;
        right: 0;
        height: auto;
        width: 185px;

        @include respond-below(900px) {
          display: none;
        }
      }
    }

    &__button {
      margin-top: 60px;
      text-align: center;
    }

    &__items {
      border-bottom: 1px solid $lightgrey;
      border-top: 1px solid $lightgrey;
      display: flex;
      flex-direction: column;
      padding: 40px 0;

      @include respond() {
        flex-direction: row;
      }
    }

    &__item {
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      margin-bottom: 20px;

      @include respond(420px) {
        flex-direction: row;
      }

      &__image {
        flex-basis: 50%;
        padding-right: 30px;
        position: relative;

        img {
          height: auto;
          width: 100%;
        }

        &__icon {
          align-items: center;
          background-color: $forest;
          border-radius: 50%;
          display: flex;
          height: 50px;
          justify-content: center;
          position: absolute;
          right: 5px;
          top: 5px;
          width: 50px;

          @include respond() {
            height: 60px;
            right: 0;
            width: 60px;
          }

          img {
            width: 35px;
          }
        }
      }

      &__info {
        display: flex;
        flex-basis: 50%;
        flex-direction: column;
        justify-content: center;
        padding-right: 30px;
        padding-top: 55px;

        h3 {
          @include header-five;
          color: $grey;
          margin-bottom: 10px;
        }

        h4 {
          @include header-three;
          margin-bottom: 10px;
        }

        &__host {
          color: $darkgrey;
          font-size: 13px;
        }

        &__description {
          font-size: 15px;
          line-height: 22px;
        }

        &__readmore {
          a {
            @include body-small-link;
          }
        }
      }
    }
  }

  &__spotlight {
    background-color: $forest;
    color: $sand;
    padding-bottom: 80px;
    padding-top: 60px;

    &__wrapper {
      @include layout();
      @include gutter();
    }

    &__header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      padding-bottom: 30px;

      h2 {
        @include header-two;
        color: $sand;
      }

      p {
        @include body-small;
        margin: 10px 0 0 0;
      }
    }

    &__feature {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      display: flex;
      flex-direction: column;
      padding: 40px 0;

      @include respond() {
        flex-direction: row;
      }

      &__profile {
        display: flex;
        flex-direction: column;
        position: relative;

        @include respond(600px) {
          flex-basis: 70%;
          flex-direction: row;
        }

        @include respond() {
          border-right: 1px solid rgba(255, 255, 255, 0.4);
        }

        &__image {
          padding-right: 40px;
          img {
            height: auto;
            width: 250px;
          }
        }

        &__info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @include respond-below() {
            padding-bottom: 120px;
          }

          h3 {
            @include regular-label;
            margin-bottom: 30px;

            @include respond-below() {
              padding-top: 30px;
            }
          }

          h4 {
            @include header-two;
            color: $peach;
            margin-bottom: 10px;
          }

          p {
            @include body-small;
            max-width: 330px;
            a {
              color: $sand;
              text-decoration: underline;
            }
          }

          &__icon {
            bottom: 0;
            position: absolute;
            right: 30px;

            @include respond-below(1080px) {
              display: none;
            }
          }
        }
      }

      &__getToKnow {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 40px;
        position: relative;

        @include respond() {
          flex-basis: 30%;
        }

        @include respond-below() {
          margin: 0 auto;
          max-width: 400px;
        }

        img {
          left: -2px;
          position: absolute;
          top: -80px;
        }

        &__item {
          background: linear-gradient(0deg, #fff7ee, #fff7ee), #ffffff;
          border: 2px solid #f4bf84;
          box-sizing: border-box;
          border-radius: 15px;
          display: block;
          margin-bottom: 20px;
          max-width: 290px;
          padding: 24px 20px;
          width: 100%;

          span {
            display: block;
          }

          span:first-child {
            color: $forest;
            @include header-three;
            margin-bottom: 25px;
          }
          span:last-child {
            color: $darkgrey;
            font-size: 13px;
            line-height: 20px;
          }
        }
      }
    }

    &__button {
      margin-top: 30px;
      text-align: center;
    }
  }
}

.completeProfile {
  @include z1;
  background-color: $peach;
  border-radius: 15px;
  margin-bottom: 30px;
  max-width: 370px;
  padding: 40px 30px;
  position: relative;
  text-align: center;
  width: 100%;

  h3 {
    @include header-three;
    margin-bottom: 15px;
  }

  p {
    @include body-caption;
    margin-bottom: 30px;
  }

  &__image {
    @include z1;
    background-color: $peach;
    border-radius: 50%;
    border: 2px solid $peach;
    height: 90px;
    position: relative;
    top: -85px;
    width: 90px;
  }
}
