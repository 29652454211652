@import "styles/mixins.scss";
@import "styles/variables.scss";

.events {
  padding-bottom: 30px;

  &__header {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    padding: 120px 0 100px;
    position: relative;
    width: 100%;

    @include respond() {
      min-height: 400px;
      overflow: visible;
      padding: 160px 0 100px;
    }

    h1 {
      @include header-one;
      max-width: 540px;
      position: relative;
      z-index: 1;
    }

    &__wrapper {
      @include gutter;
      @include layout();
      width: 100%;
    }

    &__upcoming {
      @include gutter;
      @include layout();
      left: 50%;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: 100%;

      > div {
        max-width: 735px;
        width: 100%;
      }
    }

    &__illo {
      bottom: -100px;
      opacity: 0.4;
      position: absolute;
      right: 0;
      width: 600px;

      img {
        height: auto;
        width: 100%;
      }

      @include respond(1060px) {
        opacity: 1;
      }
    }
  }

  &__attending {
    @include gutter;
    @include layout;
    border-top: 1px solid $lightgrey;
    padding-bottom: 60px;
    padding-top: 50px;
    position: relative;

    h2 {
      @include header-two;
    }

    &__button {
      outline: 0;
      cursor: pointer;
      background-color: transparent;
      touch-action: manipulation;
      position: absolute;
      z-index: 1;
      top: 50%;
      border: 0;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;
      fill: $terracotta;
      padding: 0;

      svg {
        width: 100%;
        height: 100%;
      }

      &__prev {
        left: 5px;
        @include respond(1200px) {
          left: -15px;
        }
      }

      &__next {
        right: 5px;

        @include respond(1200px) {
          right: -15px;
        }
      }
    }

    &__wrapper {
      width: 100%;
      overflow: hidden;
      position: relative;
    }

    &__items {
      display: flex;
      user-select: none;
      position: relative;
      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -webkit-tap-highlight-color: transparent;
      padding-right: 65px;
      width: calc(100% + 50px);

      @include respond(600px) {
        width: calc(100% + 100px);
      }

      > a {
        // flex-basis: 100%;
        flex-shrink: 0;
        width: calc(100% - 50px);
        margin-left: 10px;
        margin-right: 10px;

        @include respond(600px) {
          // flex-basis: 540px;
          width: 540px;

          margin-left: 15px;
          margin-right: 15px;
        }

        @include respond(1120px) {
          max-width: calc(50% - 50px);
        }
      }
    }

    &__empty {
      margin-top: 20px;

      h4 {
        @include header-three;
      }
    }
  }

  &__filter {
    @include layout;
    @include gutter;
    align-items: center;
    border-top: 1px solid $lightgrey;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 30px;
    padding-top: 30px;
    position: relative;
    z-index: 2;

    @include respond() {
      flex-direction: row;
    }

    &__invite {
      @include respond() {
        flex-basis: 50%;
      }
    }
  }

  &__filtering {
    overflow: hidden;

    @include respond() {
      flex-basis: 50%;
    }

    &__mobile {
      display: none;
      @include respond-below() {
        display: flex;
        justify-content: center;
        padding-bottom: 30px;
      }
    }

    &__wrapper {
      display: flex;
      flex-wrap: wrap;

      @include respond-below($tablet) {
        background-color: $white;
        height: 100vh;
        left: 0;
        overflow: scroll;
        padding: 60px 25px;
        position: fixed;
        top: 0;
        transform: translateY(110vh);
        transition: transform 400ms ease;
        width: 100%;
        z-index: 300;
      }

      &__open {
        @include respond-below($tablet) {
          transform: translateY(0);
        }
      }
    }

    &__mobileClose {
      display: none;
      @include respond-below($tablet) {
        display: block;
        position: absolute;
        padding: 10px;
        right: 10px;
        top: 10px;
        z-index: 400;
      }
    }

    h2 {
      @include header-two;
      margin-bottom: 30px;
    }

    &__type {
      display: flex;

      @include respond-below() {
        justify-content: center;
      }

      &__item {
        @include regular-label;
        border-bottom: 5px solid transparent;
        color: $grey;
        display: inline-block;
        margin-right: 25px;
        padding-bottom: 10px;

        &:last-of-type {
          margin-right: 0;
        }

        &__active {
          border-bottom: 5px solid $terracotta;
          color: $forest;
        }
      }
    }
    &__options {
      display: flex;
      margin-top: 30px;

      @include respond-below($tablet) {
        justify-content: center;
      }

      &__wrapper {
        display: flex;
        flex-wrap: wrap;

        @include respond-below($tablet) {
          background-color: $white;
          height: 100vh;
          overflow: scroll;
          padding: 60px 0;
          position: fixed;
          top: 0;
          transform: translateY(110vh);
          transition: transform 400ms ease;
          z-index: 300;
        }

        &__open {
          @include respond-below($tablet) {
            transform: translateY(0);
          }
        }
      }
    }
  }

  &__list {
    @include layout;
    @include gutter;

    &__noEvents {
      padding: 60px 0;
      text-align: center;

      h2 {
        @include header-two;
        margin-bottom: 30px;
      }
    }
  }
}

.explanationCard {
  background-color: $peach;
  border-radius: 15px;
  color: $forest;
  display: flex;
  flex-direction: column;
  max-width: 550px;
  padding: 30px;
  position: relative;
  width: 100%;

  @include respond() {
    flex-direction: row;
  }

  &__cta {
    @include respond() {
      flex-basis: 175px;
    }

    h2 {
      @include header-three;
      margin-bottom: 20px;
    }

    a {
      width: 100% !important;
    }
  }

  &__text {
    padding-top: 20px;

    @include respond() {
      flex-basis: 315px;
      padding-left: 30px;
      padding-top: 0;
    }

    p {
      @include body-caption;
    }
  }
}

.event {
  @include header-top-padding();
  @include gutter;
  padding-bottom: 120px;

  &__isCancelled {
    h1 {
      text-decoration: line-through;
    }

    button {
      display: none;
    }
  }

  &__wrapper {
    @include layout();
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond() {
      flex-direction: row;
      padding-bottom: 80px;
    }
  }

  &__edit {
    @include layout();
    align-items: center;
    background-color: $peach;
    border-radius: 15px;
    color: $forest;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto 30px;
    padding: 15px 30px;
    text-align: center;

    @include respond() {
      flex-direction: row;
      margin-top: 50px;
    }

    p {
      @include header-four;
      margin-bottom: 0;

      @include respond-below() {
        &:last-of-type {
          padding-top: 20px;
        }
      }

      a,
      span {
        color: $forest;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &__cancelled {
    @include layout();
    align-items: center;
    background-color: $terracotta;
    border-radius: 15px;
    color: $white;
    margin: 0 auto 30px;
    padding: 15px 30px;
    text-align: center;

    @include respond() {
      margin-top: 50px;
    }

    p {
      @include header-four;
      margin-bottom: 0;
    }
  }

  &__RSVPedit {
    @include layout();
    align-items: flex-start;
    background-color: $peach;
    border-radius: 15px;
    color: $forest;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto 30px;
    padding: 20px;

    @include respond() {
      flex-direction: row;
      padding: 30px;
    }

    h2 {
      @include header-two;
    }

    h3 {
      @include header-five;
      margin: 10px 0 20px;

      @include respond() {
        margin: 0 0 20px;
      }
    }

    &__title {
      width: 100%;
      text-align: center;

      @include respond() {
        flex-basis: 20%;
        text-align: left;
        width: auto;
      }
    }

    &__note {
      margin-bottom: 15px;
      text-align: center;

      @include respond() {
        flex-basis: 55%;
        padding: 0 40px;
        text-align: left;
      }
    }

    &__utility {
      text-align: center;
      width: 100%;

      @include respond() {
        flex-basis: 25%;
        width: auto;
      }

      button {
        margin-bottom: 10px;
      }

      p {
        @include layout(220px);
        @include body-caption;
      }
    }
  }

  &__image {
    @include respond() {
      flex-basis: 25.5%;
      padding-right: 55px;
    }

    &__wrapper {
      position: relative;

      img {
        width: 100%;
      }
    }

    &__type {
      align-items: center;
      background-color: $forest;
      border-radius: 50%;
      display: flex;
      height: 50px;
      justify-content: center;
      position: absolute;
      right: 10px;
      top: -25px;
      width: 50px;

      @include respond() {
        height: 75px;
        right: -36.5px;
        top: 30px;
        width: 75px;
      }

      img {
        max-width: 25px;
        width: 100%;

        @include respond() {
          max-width: 40px;
        }
      }
    }
  }

  &__body {
    @include respond() {
      flex-basis: 52.5%;
      padding-right: 40px;
      padding-top: 60px;
    }
  }

  &__info {
    h1 {
      @include header-two;
      margin-bottom: 40px;
    }

    &__location {
      @include header-five;
      color: $grey;
      padding-top: 30px;

      @include respond() {
        padding-top: 0px;
      }
    }

    &__text {
      padding-bottom: 40px;

      @include respond() {
        padding-bottom: 60px;
      }

      p {
        @include body-one;
      }
    }
  }

  &__expect {
    h2 {
      @include header-three;
      display: flex;

      span {
        width: 100%;

        &:first-of-type {
          min-width: 155px;

          @include respond() {
            max-width: 190px;
            min-width: 185px;
          }
        }

        &:last-of-type {
          border-bottom: 1px solid $forestTransparent;
          margin-left: 15px;
          position: relative;
          top: -11px;
        }
      }
    }

    &__item {
      align-items: center;
      display: flex;
      padding-bottom: 20px;

      div {
        width: 45px;
      }

      img {
        height: 24px;
        margin-right: 20px;
        width: auto;
      }

      p {
        line-height: 24px;
        margin: 0;
      }
    }

    &__items {
      display: grid;
      grid-auto-rows: 1fr;
      grid-gap: 10px;
      grid-template-columns: repeat(2, 1fr);
      padding: 40px 0;
    }
  }

  &__details {
    padding-bottom: 30px;
    width: 100%;

    @include respond() {
      flex-basis: 22%;
      padding-top: 80px;
    }

    &__wrapper {
      max-width: 220px;
    }

    h3 {
      @include body-two-bold;
      display: flex;
      margin-top: 40px;
      margin-bottom: 20px;
      position: relative;

      span {
        border-bottom: 1px solid $forestTransparent;
        margin-left: 15px;
        position: relative;
        top: -11px;
        width: 100%;
      }
    }

    p {
      @include body-small;
    }

    button {
      margin-top: 30px;
    }
  }

  &__question {
    @include layout();
    margin-top: 50px;
    position: relative;
    text-align: center;

    &__top {
      border-top: 1px solid $forestTransparent;
      position: relative;

      &__hostImage {
        background-color: $peach;
        background-size: cover;
        border: 2px solid $peach;
        border-radius: 50%;
        height: 70px;
        left: 50%;
        position: absolute;
        top: -35px;
        transform: translateX(-50%);
        width: 70px;
        z-index: 2;

        @include respond() {
          height: 100px;
          top: -50px;
          width: 100px;
        }

        svg {
          position: absolute;
          right: -12px;
          top: -30px;
          z-index: 3;
        }

        &:after {
          background-color: $sand;
          content: "";
          height: 70px;
          width: 30px;
          position: absolute;
          right: -32px;
          z-index: 1;
        }
        &:before {
          background-color: $sand;
          content: "";
          height: 70px;
          width: 30px;
          position: absolute;
          left: -32px;
          z-index: 1;
        }
      }

      h2 {
        @include header-three;
        margin-top: 50px;
        margin-bottom: 30px;

        @include respond() {
          margin-top: 80px;
          margin-bottom: 60px;
        }
      }
    }

    h3 {
      @include header-two;
      @include layout(725px);
    }

    &__answers {
      display: grid;
      grid-gap: 40px;
      grid-row-gap: 80px;
      grid-template-columns: repeat(1, 1fr);
      padding-top: 60px;

      @include respond($tablet) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  &__attendees {
    @include layout();
    text-align: center;

    h2 {
      @include header-two;
      padding-bottom: 30px;

      @include respond() {
        padding-bottom: 80px;
      }
    }

    &__wrapper {
      display: grid;
      grid-column-gap: 10px;
      grid-row-gap: 50px;
      grid-template-columns: repeat(2, 1fr);
      justify-content: space-between;
      padding-bottom: 30px;

      @include respond() {
        padding-bottom: 50px;
        grid-column-gap: 35px;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  &__modal {
    @include z4;
    align-items: center;
    display: none;
    background-color: rgba(0, 0, 0, 0.4);
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    left: 0;
    overflow: scroll;
    position: fixed;
    top: 0;
    width: 100vw;
    scrollbar-width: none;

    @include respond() {
      flex-direction: row;
    }

    &__active {
      display: flex;
    }

    &__body {
      height: 100%;
      min-height: 80vh;

      &__wrapper {
        @include z5;
        @include layout(900px);
        background-color: $sand;
        max-height: 90vh;
        overflow: scroll;
        position: relative;
        scrollbar-width: none;
        width: 100%;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          height: 0;
          width: 0;
          background: transparent;
        }
      }
    }

    &__wrapper {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 30px 25px 60px;

      @include respond() {
        flex-direction: row;
        padding: 30px 40px;
      }
    }

    &__close {
      cursor: pointer;
      right: 10px;
      padding: 5px;
      position: absolute;
      top: 10px;

      @include respond() {
        right: 20px;
      }
    }

    &__top {
      border-bottom: 1px solid $grey;
      padding: 20px;

      p {
        @include mini-label;
        margin-bottom: 0;
        text-align: center;
      }
    }

    &__left {
      padding-top: 30px;
      position: relative;

      @include respond() {
        flex-basis: 35%;
        padding: 50px 20px 0 0;

        &:after {
          // FROGS: url imports node-sass
          // content: url("icons/pencil-scribble.png");
          position: absolute;
          right: -30px;
          top: 0;
        }
      }

      &__wrapper {
        max-width: 290px;
      }

      &__image {
        background-color: $peach;
        background-size: cover;
        border: 2px solid $peach;
        border-radius: 50%;
        height: 50px;
        left: 0;
        position: absolute;
        top: -25px;
        width: 50px;

        @include respond() {
          height: 75px;
          left: 20px;
          right: auto;
          top: -67.5px;
          width: 75px;
        }
      }

      h2 {
        @include header-two;
        margin-bottom: 40px;
      }

      p {
        max-width: 220px;
      }

      &__noteTitle {
        @include mini-label;
      }
    }

    &__right {
      padding-top: 30px;
      text-align: center;

      @include respond() {
        flex-basis: 65%;
        padding-left: 50px;
        padding-top: 50px;
      }

      h3 {
        @include body-two-bold;
        margin-bottom: 5px;
      }

      p {
        @include body-small;
        margin-bottom: 0;
      }

      a {
        margin-bottom: 30px;
        margin-top: 20px;
      }

      section {
        padding-bottom: 20px;
      }

      &__box {
        @include body-one;
        background-color: $white;
        border: 2px solid $peach;
        padding: 20px;
        text-align: left;
        width: 100%;
      }

      &__button {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        p {
          @include header-five;
        }
      }
    }
  }

  &__congratsModal {
    &__left {
      @include respond() {
        flex-basis: 50%;
      }
    }

    &__right {
      @include respond() {
        flex-basis: 50%;
      }
    }
  }
}

.editEvent {
  @include gutter;
  @include header-top-padding;
  padding-bottom: 60px;

  &__wrapper {
    @include layout(640px);
    text-align: center;

    h1 {
      @include header-two;
      margin-bottom: 30px;
    }

    section {
      h5 {
        @include mini-label;
        font-family: "Beatrice-Regular", "sans-serif";
        font-weight: bold;
        margin: 0;
        margin-bottom: 10px;
        text-align: left;
        text-align: left;
      }
    }

    button {
      margin-bottom: 20px;
      margin-top: 60px;
    }
  }

  &__image {
    button {
      margin: 10px auto;
    }
  }

  &__returnMessage {
    @include header-four;

    a {
      color: $forest;
      text-decoration: underline;
    }
  }
}

.manageAtt {
  text-align: left;

  &__item {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    position: relative;

    &__image {
      background-color: $peach;
      background-size: cover;
      border-radius: 50%;
      height: 50px;
      margin-right: 20px;
      width: 50px;
    }

    &__name {
      h4 {
        @include header-four;
      }
      p {
        @include body-caption;
      }
    }

    &__email {
      padding-left: 30px;
    }

    &__remove {
      position: absolute;
      right: 0;
    }
  }
}

.virtualFlag {
  @include regular-label;
  background-color: $peach;
  color: $forest;
  border-radius: 6px;
  padding: 1px 6px;
}

.virtualFlag {
  @include regular-label;
  background-color: $peach;
  color: $forest;
  border-radius: 6px;
  padding: 1px 6px;
}
