@import "styles/mixins.scss";
@import "styles/variables.scss";

.upcomingPast {
  text-align: center;

  &__item {
    @include header-five;
    border-bottom: 5px solid transparent;
    color: $forest;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 30px;
    opacity: 0.5;
    padding-bottom: 10px;
    transition: opacity 200ms ease, border-bottom 200ms ease;

    &:last-of-type {
      margin-right: 0;
    }

    &__active {
      border-bottom: 5px solid $terracotta;
      opacity: 1;
    }

    &:hover {
      border-bottom: 5px solid $peach;
    }
  }
}
