@import "styles/mixins.scss";
@import "styles/variables.scss";

.membership {
  &__header {
    background-color: $forest;
    min-height: calc(100vh - 62px); //added

    &__wrapper {
      @include layout(1200px);
      @include gutter();
      padding-bottom: 60px;
      padding-top: 125px;
      position: relative;
      text-align: center;

      @include respond() {
        padding-bottom: 80px;
        padding-top: 185px;
      }

      h1 {
        @include header-one-display();
        color: $peach;
        margin-bottom: 20px;
        position: relative;
        z-index: 3;

        @include respond() {
          margin-bottom: 40px;
        }
      }

      p {
        @include body-large();
        @include layout(730px);
        color: $sand;
        position: relative;
        z-index: 3;
      }
    }

    &__greenSpace {
      display: none;

      @include respond() {
        background-color: $forest;
        display: block;
        height: 50px;
        left: 114px;
        position: absolute;
        top: -25px;
        width: 150px;
        z-index: 3;
      }
    }

    &__border {
      @include respond(720px) {
        max-width: 1200px;
        border: 2px solid #f5c38f; // added
        height: 100%;
        max-height: 480px; // added
        left: 50%;
        position: absolute;
        top: 110px;
        transform: translateX(-50%);
        width: 100%;
        z-index: 2;
      }
    }
  }

  &__imageSlider {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    z-index: 1;

    &__border {
      @include respond(720px) {
        max-width: 1200px;
        border-left: 2px solid #f5c38f;
        border-right: 2px solid #f5c38f;
        height: 300px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 100%;
        z-index: 2;
      }
    }
  }

  &__waitlist {
    @include layout(1200px);
    @include gutter();
    background-color: $peach;
    padding-bottom: 60px;
    padding-top: 60px;
    text-align: center;

    @include respond() {
      padding-bottom: 80px;
      padding-top: 70px;
    }

    &__wrapper {
      @include layout(540px);

      h2 {
        @include header-one();
        margin-bottom: 20px;
      }

      p {
        @include body-one();
        margin-bottom: 10px;

        @include respond() {
          margin-bottom: 30px;
        }
      }

      input {
        border: 2px solid $forest;
        margin-bottom: 5px;
      }
      button {
        margin-top: 15px;
      }
    }

    &__modal {
      background-color: rgba(0, 0, 0, 0.5);
      height: 100vh;
      left: 0;
      opacity: 0;
      position: fixed;
      top: 0;
      visibility: hidden;
      width: 100vw;
      z-index: 9999;

      &__close {
        cursor: pointer;
        right: 10px;
        padding: 5px;
        position: absolute;
        top: 10px;

        @include respond() {
          right: 20px;
        }
      }

      &__selectBoxes {
        display: grid;
        grid-auto-rows: 1fr;
        grid-gap: 10px;
        grid-template-columns: repeat(1, 1fr);

        @include respond($tablet) {
          grid-template-columns: repeat(3, 1fr);
        }
      }

      &__wrapper {
        background-color: $sand;
        height: calc(100vh - 40px);
        margin: 20px auto;
        max-width: 815px;
        overflow: scroll;
        padding: 60px 25px;
        position: relative;
        width: calc(100vw - 30px);

        @include respond() {
          height: calc(100vh - 80px);
          margin: 40px auto;
          width: 100%;
        }

        h2 {
          @include header-two;
          @include layout(545px);
          margin-bottom: 30px;
        }

        h3 {
          @include layout(545px);
          @include header-four;
          margin-bottom: 10px;
          margin-top: 20px;

          @include respond($tablet) {
            margin-top: 50px;
          }
        }

        h4 {
          @include layout(545px);
          @include body-small;
          font-weight: normal;
          margin-bottom: 10px;

          @include respond($tablet) {
            margin-bottom: 30px;
          }
        }

        p {
          @include body-one;
          @include layout(545px);
          border-bottom: 1px solid #d4dbdb;
          margin-bottom: 30px;
          padding-bottom: 30px;
        }

        form {
          @include layout(545px);
          padding-bottom: 30px;
        }
      }

      &__active {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__details {
    @include layout(1200px);
    @include gutter();
    &__header {
      padding-bottom: 30px;
      padding-top: 60px;
      text-align: center;

      @include respond() {
        padding-bottom: 80px;
        padding-top: 80px;
      }

      h2 {
        @include header-one();
        margin-bottom: 20px;
      }

      p {
        @include body-one();
        margin-bottom: 20px;
        @include respond() {
          margin-bottom: 40px;
        }
      }

      &__spacer {
        @include layout(110px);
        border-bottom: 5px solid $terracotta;
        margin-bottom: 20px;

        @include respond() {
          margin-bottom: 60px;
        }
      }
    }

    &__sidebyside {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 40px;
      padding-top: 40px;
      position: relative;

      @include respond() {
        flex-direction: row;
        padding-bottom: 225px;
        padding-top: 80px;

        &:nth-of-type(2n - 1) {
          flex-direction: row-reverse;

          .membership__details__sidebyside__title {
            right: -50%;
          }

          .membership__details__sidebyside__image__photo__wrapper {
            left: 0;
            right: auto;
          }

          .membership__details__sidebyside__image__illo {
            left: auto;
            right: 0;
          }
        }
      }

      &__title {
        @include regular-label();
        color: $grey;
        position: relative;
        width: 100%;

        &::after {
          content: "";
          margin-left: 10px;
          height: 1px;
          background-color: #d4dbdb;
          position: absolute;
          top: 5px;
          width: 100%;
        }

        &::before {
          content: "";
          margin-right: 10px;
          height: 1px;
          background-color: #d4dbdb;
          position: absolute;
          right: 100%;
          top: 5px;
          width: 100%;
        }

        &__wrapper {
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 5px;
          width: 100%;
        }
      }

      &__text {
        flex-basis: 50%;
        padding-bottom: 40px;

        @include respond-below() {
          margin: 0 auto;
          max-width: 445px;
        }

        h4 {
          @include header-two();
          margin-bottom: 20px;
          max-width: 445px;
        }

        p {
          @include body-one();
          max-width: 445px;
          padding-bottom: 20px;
        }
      }

      &__image {
        align-self: stretch;
        flex-basis: 50%;
        overflow: visible;
        position: relative;
        width: 100%;

        @include respond-below() {
          text-align: center;
        }

        &__photo {
          height: auto;
          object-fit: contain;
          width: 100%;

          &__wrapper {
            align-items: flex-start;
            display: flex;
            max-height: 485px;
            max-width: 445px;
            width: 100%;

            @include respond() {
              position: absolute;
              right: 0;
              top: -100px;
              width: 100%;
              z-index: 1;
            }
          }

          &__bottomShort {
            @include respond() {
              top: 0;
            }
          }
        }

        &__illo {
          margin-top: -80px;
          max-width: 345px;
          width: 100%;

          @include respond() {
            bottom: 0;
            margin: 0;
            left: 0;
            position: absolute;
            z-index: 2;
          }
        }

        &__eventCard {
          margin-top: -80px;
          max-width: 545px;
          width: 100%;

          @include respond() {
            bottom: 40px;
            margin: 0;
            left: -30px;
            position: absolute;
            z-index: 2;
          }

          img {
            width: 100%;
          }
        }
      }
    }

    p.membership__details__footnote {
      font-size: 13px;
      font-style: italic;
      line-height: 20px;
    }
  }

  &__quotes {
    @include gutter;
    @include layout();
    background-color: $peach;

    &__wrapper {
      @include layout(925px);
      padding-bottom: 45px;
      padding-top: 60px;
      position: relative;

      @include respond() {
        padding-bottom: 60px;
        padding-top: 85px;
      }
    }

    &__section {
      @include header-four();
      @include layout(815px);
      margin-bottom: 40px;
      min-height: 125px;
      text-align: center;

      @include respond(700px) {
        @include header-two();
        min-height: 290px;
      }
    }

    &__quote {
      left: 50%;
      opacity: 0;
      position: absolute;
      transition: opacity 200ms ease;
      transform: translateX(-50%);
      width: 100%;

      &__active {
        opacity: 1;
      }

      &__text {
        @include layout(815px);

        @include respond-below(700px) {
          max-width: 445px;
        }
      }

      &__quoteTop {
        display: none;
        @include respond() {
          display: block;
          left: -20px;
          position: absolute;
          top: 0;
        }
      }

      &__quoteBottom {
        display: none;
        @include respond() {
          display: block;
          bottom: 0;
          position: absolute;
          right: -10px;
          transform: rotate(180deg);
        }
      }
    }

    &__toggle {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 40px;

      &__item {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        // height: 170px;
        justify-content: flex-start;
        padding-top: 25px;
        text-align: center;
        transition: padding-top 200ms ease;
        width: 120px;

        p {
          @include body-caption;
          margin-bottom: 5px;
          opacity: 0;
          transition: opacity 200ms ease;

          &:first-of-type {
            @include header-four;
            margin-top: 15px;
          }
        }

        &__active {
          padding-top: 0;

          p {
            opacity: 1;
          }
        }
      }
    }

    &__image {
      background-color: white;
      background-size: cover;
      border: 2px solid rgba(0, 0, 0, 0);
      border-radius: 50%;
      height: 45px;
      opacity: 0.5;
      transition: border 200ms ease, height 200ms ease, opacity 300ms ease,
        width 200ms ease;
      width: 45px;

      @include respond() {
        height: 35px;
        width: 35px;
      }

      &__active {
        border: 2px solid $terracotta;
        height: 65px;
        opacity: 1;
        width: 65px;

        @include respond() {
          height: 75px;
          width: 75px;
        }
      }
    }
  }

  &__ready {
    @include layout(595px);
    @include gutter;
    padding-bottom: 80px;
    padding-top: 70px;
    text-align: center;

    &__wrapper {
      @include layout();
      @include gutter;
      border: 2px solid $peach;
      margin-bottom: 60px;
    }

    h2 {
      @include header-one;
      margin-bottom: 30px;
    }

    p {
      @include body-one;
      margin-bottom: 50px;
    }
  }
}

.embla {
  overflow: hidden;
  height: 300px;
  position: relative;
  width: 100%;

  &__container {
    display: flex;
    user-select: none;
    position: relative;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin-left: -10px;
  }
  &__image {
    background-size: cover;
    background-position: center;
    height: 300px;
    position: relative;
    min-width: 70%;

    @include respond(420px) {
      min-width: 50%;
    }

    @include respond(720px) {
      min-width: 35%;
    }

    img {
      min-height: 100%;
      min-width: 100%;
      width: auto;
      height: auto;
    }
  }

  &__button {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    touch-action: manipulation;
    position: absolute;
    z-index: 1;
    top: 50%;
    border: 0;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    fill: $terracotta;
    padding: 0;

    svg {
      width: 100%;
      height: 100%;
    }

    &__prev {
      left: 27px;
    }

    &__next {
      right: 27px;
    }
  }
}
