@import "styles/mixins.scss";
@import "styles/variables.scss";

.fourohfour {
  @include gutter();
  @include layout();
  @include vertical-spacing;
  text-align: center;

  h1 {
    @include header-one;
    @include layout(540px);
    padding-bottom: 60px;
  }

  a {
    @include body-one;
    color: $forest;
    text-decoration: underline;
  }
}
