@import "styles/mixins.scss";
@import "styles/variables.scss";

.story {
  &__header {
    background-color: $forest;
    background-position: center;
    background-size: cover;

    &__wrapper {
      @include layout(1200px);
      @include gutter();
      padding-bottom: 60px;
      padding-top: 145px;
      position: relative;
      text-align: center;

      @include respond() {
        padding-bottom: 80px;
        padding-top: 205px;
      }

      h1 {
        @include header-one-display();
        color: $offwhite;
        margin-bottom: 20px;
        position: relative;
        z-index: 3;

        @include respond() {
          margin-bottom: 40px;
        }
      }

      p {
        @include body-large();
        @include layout(730px);
        color: $sand;
        position: relative;
        z-index: 3;
      }
    }

    &__border {
      @include respond(720px) {
        max-width: 1200px;
        border-left: 2px solid #f5c38f;
        border-right: 2px solid #f5c38f;
        border-top: 2px solid #f5c38f;
        height: 100%;
        left: 50%;
        position: absolute;
        top: 130px;
        transform: translateX(-50%);
        width: 100%;
        z-index: 2;
      }
    }
  }

  &__letter {
    @include gutter;
    @include layout(1200px);
    border-bottom: 2px solid #f5c38f;
    border-left: 2px solid #f5c38f;
    border-right: 2px solid #f5c38f;
    padding-bottom: 30px;
    padding-top: 35px;

    @include respond() {
      padding-bottom: 60px;
      padding-top: 70px;
    }

    h2 {
      @include layout(1030px);
      @include regular-label();
      color: $grey;
      margin-bottom: 30px;
      overflow: hidden;
      position: relative;
      text-align: center;

      &:before,
      &:after {
        background-color: #d4dbdb;
        content: "\a0";
        height: 1px;
        margin-left: 10px;
        overflow: hidden;
        position: absolute;
        top: 5px;
        width: 50%;
      }

      &:before {
        margin-left: calc(-50% - 10px);
        text-align: right;
      }
    }

    h3 {
      @include header-two();
      margin: 0px auto 40px;
      max-width: 775px;
      text-align: center;
    }

    &__rows {
      @include layout(1030px);
      display: flex;
      flex-wrap: wrap;

      @include respond() {
        flex-wrap: nowrap;
      }
    }

    &__row {
      padding-right: 20px;
      padding-left: 20px;

      p {
        @include body-one();
        @include respond() {
          max-width: 540px;
        }
      }
    }

    &__attribution {
      @include layout(1030px);
      @include gutter;
      padding-top: 40px;
      text-align: center;

      h4 {
        @include header-three();
        margin: 0 auto 10px;
        overflow: hidden;
        position: relative;

        &:before,
        &:after {
          background-color: #d4dbdb;
          content: "\a0";
          height: 1px;
          margin-left: 10px;
          overflow: hidden;
          position: absolute;
          top: 11px;
          width: 50%;
        }

        &:before {
          margin-left: calc(-50% - 10px);
          text-align: right;
        }
      }

      p {
        @include body-small;
        margin-bottom: 0;
      }
    }
  }

  &__gatherings {
    @include gutter;
    padding-bottom: 40px;
    padding-top: 80px;

    &__top {
      @include layout(490px);
      padding-bottom: 40px;
      text-align: center;

      h2 {
        @include header-one;
        margin-bottom: 20px;
      }

      p {
        @include body-one;
      }
    }

    &__events {
      &__header {
        p {
          @include header-three;
        }
      }
      &__wrapper {
        @include layout(925px);
      }
    }
  }
}

.eventCard {
  align-items: flex-start;
  border-top: 1px solid $lightgrey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 40px 40px 0;

  @include respond($tablet) {
    flex-direction: row;
  }

  &__date {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include respond($tablet) {
      align-items: flex-start;
      flex-basis: 15%;
      flex-direction: column;
      justify-content: flex-start;
    }

    &__wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-shrink: 0;
      text-align: center;
      width: 60px;

      @include respond($mobile) {
        width: 65px;
      }

      @include respond() {
        width: 85px;
      }

      p {
        @include header-five;
        color: $terracotta;

        span {
          @include number;

          @include respond() {
            line-height: 66px;
          }
        }
      }
    }
  }

  &__details {
    @include respond($tablet) {
      flex-basis: 70%;
    }

    h3 {
      @include regular-label;
      color: $grey;
      margin-bottom: 10px;
    }

    h4 {
      @include header-three;
      color: $forest;
      margin-bottom: 5px;
    }

    p {
      @include body-caption;
      max-width: 600px;

      &:first-of-type {
        margin-bottom: 20px;
      }
    }
  }

  &__host {
    padding-top: 15px;
    text-align: center;

    @include respond($tablet) {
      flex-basis: 15%;
    }

    &__image {
      background-color: $peach;
      background-position: center;
      background-size: cover;
      border: 2px solid $peach;
      border-radius: 50%;
      height: 50px;
      margin: auto;
      position: relative;
      width: 50px;

      svg {
        position: absolute;
        right: -20px;
        top: -30px;

        @include respond($tablet) {
          right: -15px;
          top: -30px;
        }
      }

      @include respond($tablet) {
        height: 75px;
        width: 75px;
      }
    }

    h4 {
      @include body-two-bold;
      margin-top: -1px;

      @include respond($tablet) {
        margin-top: 13px;
      }
    }

    p {
      display: none;

      @include respond($mobile) {
        display: block;
        font-size: 11px;
      }

      @include respond() {
        font-size: 13px;
        margin-top: 5px;
      }
    }
  }
}
