@import "styles/mixins.scss";
@import "styles/variables.scss";

.input {
  padding-bottom: 30px;
  position: relative;
  width: 100%;

  label {
    @include mini-label;
    margin: 0;
    margin-bottom: 10px;
    text-align: left;
    width: 100%;

    > .stripeInput {
      position: relative;
      top: 10px;
      min-height: 65px;
    }
  }

  input,
  textarea,
  select,
  .stripeInput {
    background: $white;
    border: 2px solid $peach;
    border-radius: 0;
    box-shadow: none;
    box-sizing: border-box;
    color: $forest;
    font-family: "Beatrice-Regular", "sans-serif";
    font-size: 16px;
    margin: 0;
    outline: none;
    padding: 25px 20px;
    transition: border 200ms ease;
    width: 100%;
    -webkit-appearance: none;

    &::placeholder {
      color: $grey;
      font-family: "Beatrice-Regular", "sans-serif";
    }

    &:focus {
      border: 2px solid $forest;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 200px 200px 100px white inset;
      box-shadow: 200px 200px 100px white inset;
    }
  }

  select {
    font-family: "Beatrice-Regular", sans-serif;
  }

  &__select {
    position: relative;
    z-index: 1;

    svg {
      pointer-events: none;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 0;
    }
  }

  &__atSymbol {
    position: relative;

    &:before {
      bottom: 58px;
      content: "@";
      font-size: 16px;
      left: 20px;
      position: absolute;
    }

    input {
      padding-left: 40px;
    }
  }

  &__error {
    input,
    textarea,
    select,
    .stripeInput {
      border: 2px solid $terracotta;
    }

    label {
      color: $terracotta;
    }

    &__message {
      @include body-caption;
      color: $terracotta;
      margin: 0;
      padding-top: 10px;
      text-align: left;
    }
  }

  :global {
    .react-timekeeper {
      position: fixed !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.formRow {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include respond(768px) {
    flex-direction: row;
  }

  > div {
    margin-right: 10px;
    min-width: 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

.selectBox {
  align-items: center;
  background-color: $white;
  border: 2px solid $peach;
  color: $forest;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 20px;
  text-align: center;
  transition: background-color 200ms ease;
  width: 100%;

  &__active {
    background-color: $peach;
    font-family: "Beatrice-Bold", serif;
  }
}

.selectBoxLarge {
  @include body-small;
  align-items: center;
  background-color: $white;
  border: 2px solid $peach;
  color: $forest;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: 700;
  justify-content: center;
  min-height: 100px;
  padding: 30px 20px;
  transition: background-color 200ms ease;
  width: 100%;

  &__active {
    background-color: $peach;
  }

  h3 {
    @include header-four();
    margin-bottom: 10px;
  }

  p {
    margin: 0;
  }

  &:hover {
    background-color: $peach;
  }
}

.checkbox {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  max-width: 500px;
  min-width: 200px;
  padding-bottom: 30px;
  width: 100%;

  input {
    background-color: transparent;
    border: 1px solid $forest;
    flex-shrink: 0;
    display: block;
    height: 18px;
    position: relative;
    top: -4px;
    -webkit-appearance: none;
    width: 18px;

    &:before {
      background-image: url('data:image/svg+xml;utf8,<svg width="9" height="7" xmlns="http://www.w3.org/2000/svg"><path d="M1.412 3.528L3.66 5.42l3.857-3.972" stroke="%23F5C38F" stroke-width="1.32" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      content: "";
      height: 13px;
      left: 1px;
      opacity: 0;
      position: absolute;
      top: 1px;
      transition: opacity 200ms ease;
      width: 13px;
    }

    &:checked {
      background-color: $forest;

      &:before {
        opacity: 1;
      }
    }
  }

  label {
    font-size: 13px;
    padding-left: 10px;
    text-align: left;
    transition: color 200ms ease;

    span {
      cursor: pointer;
    }

    a {
      color: $forest;
      text-decoration: underline;
    }
  }

  &__error {
    position: relative;
    top: -31px;
    left: 35px;
  }
}

.autoComplete {
  &__results {
    @include z3;
    position: absolute;
    width: 100%;

    p {
      background-color: $white;
      border-left: 2px solid $forest;
      border-right: 2px solid $forest;
      cursor: pointer;
      font-size: 18px;
      font-weight: normal;
      line-height: 1;
      margin: 0;
      padding: 12px 20px;
      text-align: left;
      transition: background-color 200ms ease, font-weight 200ms ease;
      width: 100%;

      &:last-of-type {
        border-bottom: 2px solid $forest;
      }

      &:hover {
        background-color: $peach;
        font-weight: bolder;
      }
    }
  }
}

.charCounter {
  @include z2;
  bottom: 45px;
  position: absolute;
  right: 15px;

  &__errorCorrect {
    bottom: 75px;
  }
}
