@import "styles/mixins.scss";
@import "styles/variables.scss";

.createEvent {
  min-height: calc(100vh - 120px);
  &__wrapper {
    @include gutter();
    @include layout();
    padding-bottom: 40px;
    padding-top: 80px;

    @include respond($tablet) {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 100px;
      padding-top: 140px;
    }
  }

  &__body {
    width: 100%;

    &__wrapper {
      @include layout(575px);
      text-align: center;

      button {
        margin-bottom: 20px;
      }
    }

    &__formMessage {
      @include body-caption;
      margin-top: -20px;
    }
  }

  h2 {
    @include header-three;
    margin-bottom: 30px;
  }

  section {
    margin-bottom: 20px;

    h2 {
      margin-bottom: 10px;
    }
  }

  &__selectorCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 30px;
    margin-top: 30px;

    @include respond($tablet) {
      margin-bottom: 30px;
    }

    > div {
      flex-basis: calc(50% - 15px);
      margin-bottom: 15px;

      > div {
        height: 100%;
      }
    }

    &__topic {
      display: grid;
      grid-auto-rows: 1fr;
      grid-gap: 15px;
      grid-template-columns: repeat(2, 1fr);

      @include respond($tablet) {
        grid-template-columns: repeat(3, 1fr);
      }

      > div {
        margin-bottom: 0;
      }
    }
  }

  &__suggestions {
    border: 2px solid $peach;
    border-top: none;
    margin-top: -30px;
    padding: 20px;

    h3 {
      @include header-five;
      margin-bottom: 20px;
    }

    &__wrapper {
      display: grid;
      grid-auto-rows: 1fr;
      grid-gap: 15px;
      grid-template-columns: repeat(1, 1fr);

      @include respond($tablet) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &__item {
      align-items: center;
      background-color: rgba(0, 0, 0, 0);
      border: 2px solid $peach;
      border-radius: 15px;
      color: $forest;
      cursor: pointer;
      display: flex;
      justify-content: center;
      min-height: 100px;
      padding: 10px;
      transition: border 200ms ease, background-color 200ms ease;

      &__active {
        background-color: $peach;
        border: 2px solid $peach;
        font-family: "Beatrice-Bold", serif;
      }
    }
  }
}

.eventPreview {
  @include header-top-padding();
  @include gutter;
  padding-bottom: 80px;

  &__wrapper {
    @include layout();
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 80px;
  }

  &__edit {
    @include layout();
    align-items: center;
    background-color: $peach;
    border-radius: 15px;
    color: $forest;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 0;
    padding: 15px 30px;
    text-align: center;

    @include respond() {
      flex-direction: row;
      margin-top: 50px;
    }

    p {
      @include header-four;
      margin-bottom: 0;

      @include respond-below() {
        &:last-of-type {
          padding-top: 20px;
        }
      }

      span {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.congrats {
  @include header-top-padding();
  @include gutter;
  padding-bottom: 80px;

  &__wrapper {
    @include layout();
    align-items: center;
    border: 4px solid $peach;
    display: flex;
    justify-content: space-between;
    padding: 120px 80px 30px;
  }

  &__image {
    flex-basis: 30%;

    img {
      height: auto;
      max-width: 250px;
      width: 100%;
    }
  }

  &__body {
    flex-basis: 70%;
    position: relative;
    text-align: center;

    &__wrapper {
      @include layout(540px);
    }

    h1 {
      @include header-two;
      margin-bottom: 30px;
    }

    &__message {
      @include body-one;
      margin-bottom: 50px;
    }

    &__moreInfo {
      @include body-caption;
      margin-top: 100px;
    }

    p {
      a {
        color: $forest;
        font-family: "Beatrice-Bold", serif;
        text-decoration: underline;
      }
    }
  }
}
