@import "styles/mixins.scss";
@import "styles/variables.scss";

.login {
  background-color: $forest;
  color: $nearwhite;
  text-align: center;

  &__wrapper {
    @include respond(960px) {
      display: flex;
    }
  }

  &__body {
    min-height: 100vh;
    padding-bottom: 30px;
    padding-top: 30px;
    position: relative;
    z-index: 1;

    @include respond(960px) {
      align-items: center;
      display: flex;
      flex-basis: 60%;
      justify-content: center;
      text-align: center;
    }

    &__wrapper {
      @include gutter;
      @include layout(495px);
      width: 100%;
    }

    p {
      @include body-one;
      margin: 0;

      a {
        color: $nearwhite;
        cursor: pointer;
        font-size: 13px;
      }
    }

    form {
      margin-top: 40px;
    }

    button {
      margin-bottom: 25px;
      min-width: 250px;
    }
  }

  &__sidebar {
    background-color: $sand;
    flex-basis: 40%;
    position: relative;

    img {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      min-height: 100vh;

      @include respond-below(960px) {
        display: none;
      }

      @include respond-below(1110px) {
        left: -100px;
      }
    }
  }
}

.forgotPass {
  @include z4;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: $forest;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  overflow: scroll;
  padding: 30px 25px;
  position: fixed;
  top: 0;
  width: 100vw;

  &__wrapper {
    @include layout(500px);
    @include z5;
    align-items: center;
    background-color: $sand;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 25px 60px;
    position: relative;
    width: 100%;

    @include respond() {
      padding: 30px 40px;
    }
  }

  &__close {
    cursor: pointer;
    right: 10px;
    padding: 5px;
    position: absolute;
    top: 10px;

    @include respond() {
      right: 20px;
    }
  }
}
