@import "styles/mixins.scss";
@import "styles/variables.scss";

.announcements {
  &__wrapper {
    @include gutter;
    @include layout;
  }

  &__header {
    padding-bottom: 20px;
    padding-top: 100px;

    @include respond() {
      padding-top: 160px;
    }

    h1 {
      @include header-one;
      padding-bottom: 30px;
      text-align: left;
    }
  }

  &__body {
    display: grid;
    // grid-auto-rows: 1fr;
    grid-gap: 10px;
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 60px;

    @include respond($tablet) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include respond() {
    }
  }
}

.announcementCard {
  border: 1px solid $forestTransparent;
  padding: 20px;

  h2 {
    @include header-three;
    text-align: center;
  }

  &__date {
    @include body-caption;
    margin-bottom: 0;
    margin-top: 5px;
    text-align: center;
  }

  img {
    margin: 20px 0;
    width: 100%;
  }
  &__readMore {
    margin-bottom: 0;
    text-align: right;

    a {
      @include body-small-link;
    }
  }
}

.announcement {
  @include gutter;

  &__header {
    @include layout(730px);
    padding-bottom: 20px;
    padding-top: 100px;
    text-align: center;
    @include respond() {
      padding-top: 160px;
    }
    h1 {
      @include header-five;
      color: $darkgrey;
    }

    h2 {
      @include header-one;
      @include layout(660px);
      margin-top: 30px;
      padding-bottom: 30px;
    }

    img {
      width: 100%;
    }
  }

  &__body {
    @include body-one;
    @include layout(630px);
    padding-bottom: 60px;
    padding-top: 30px;

    @include respond() {
      padding-top: 60px;
    }

    strong {
      font-family: "Beatrice-Bold", serif;
    }

    h1 {
      @include header-one;
      margin-bottom: 30px;
      margin-top: 30px;
    }

    h2 {
      @include header-two;
      margin-bottom: 30px;
      margin-top: 30px;
    }

    h3 {
      @include header-three;
      margin-bottom: 30px;
      margin-top: 30px;
    }

    a {
      text-decoration: underline;
    }
  }
}
