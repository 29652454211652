@import "styles/mixins.scss";
@import "styles/variables.scss";

.profile {
  @include gutter();
  @include header-top-padding();

  &__wrapper {
    @include layout();
    padding-bottom: 60px;
    padding-top: 10px;

    @include respond(680px) {
      padding-top: 40px;
    }
  }

  &__top {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 60px;
    text-align: center;

    @include respond(680px) {
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
      text-align: inherit;
    }

    &__image {
      position: relative;
      text-align: center;

      @include respond(680px) {
        flex-basis: 30%;
        padding-right: 40px;
      }

      &__image {
        background-color: $peach;
        background-position: center;
        background-size: cover;
        border: 2px solid $peach;
        border-radius: 50%;
        height: 150px;
        margin: 0 auto 20px;
        position: relative;
        width: 150px;

        svg {
          left: -34px;
          position: absolute;
          top: -25px;
        }

        @include respond(680px) {
          height: 200px;
          width: 200px;

          svg {
            left: -25px;
            top: -17px;
          }
        }
      }

      h1 {
        @include header-two;
      }
    }

    &__social {
      border-bottom: 5px solid $peach;
      display: inline-block;
      margin-bottom: 40px;
      margin-top: 30px;
      padding-bottom: 15px;
      text-align: center;

      @include respond(680px) {
        margin-top: 0;
        text-align: left;
      }

      a {
        padding: 10px;

        svg path {
          fill: $forest;
          transition: fill 200ms ease;
        }

        &:hover {
          svg path {
            fill: $peach;
          }
        }
      }
    }

    &__desc {
      @include respond(680px) {
        flex-basis: 50%;
        padding-right: 40px;
      }

      h3 {
        @include header-three;
        margin-bottom: 30px;
      }

      &__info {
        @include body-two-bold;

        span {
          padding-right: 8px;

          &:after {
            content: "•";
            margin-left: 4px;
          }

          &:last-of-type {
            padding-right: 0;

            &:after {
              content: "";
              margin-left: 0;
            }
          }
        }
      }

      &__desc {
        font-size: 18px;
        line-height: 32px;
      }
    }

    &__events {
      @include respond(680px) {
        flex-basis: 20%;
      }

      h3 {
        @include body-two-bold;
        padding-bottom: 20px;
      }
    }

    &__gatherings {
      display: flex;
      justify-content: space-between;
      max-width: 180px;
      padding-bottom: 30px;

      h4 {
        @include header-five;
        color: $terracotta;
        text-align: center;

        span {
          @include number;
        }
      }
    }
  }

  &__currently {
    @include layout(930px);
    padding-bottom: 100px;

    h2 {
      display: none;
    }
  }

  &__hosted {
    padding-top: 30px;

    h2 {
      @include header-two;
      margin-bottom: 30px;
      text-align: center;
    }

    &__toggle {
      text-align: center;

      &__item {
        @include header-five;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        display: inline-block;
        opacity: 0.5;
        padding-bottom: 10px;
        transition: opacity 200ms ease, border-bottom 200ms ease;

        &:first-of-type {
          margin-right: 30px;
        }

        &__active {
          border-bottom: 2px solid $peach;
          opacity: 1;
        }
      }
    }

    &__empty {
      @include layout(580px);
      border: 1px solid $forestTransparent;
      margin-top: 30px;
      padding: 40px 20px;
      text-align: center;

      h4 {
        @include header-three;
        margin-bottom: 30px;
      }
    }
  }

  &__edit {
    @include layout();
    align-items: center;
    background-color: $peach;
    border-radius: 15px;
    color: $forest;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto 30px;
    padding: 15px 30px;
    text-align: center;

    @include respond() {
      flex-direction: row;
      margin-top: 50px;
    }

    p {
      @include header-four;
      margin-bottom: 0;

      @include respond-below() {
        &:last-of-type {
          padding-top: 20px;
        }
      }

      a {
        color: $forest;
        text-decoration: underline;
      }
    }
  }
}

.currently {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(1, 1fr);

  @include respond(580px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include respond() {
    grid-template-columns: repeat(3, 1fr);
  }

  &__item {
    align-items: center;
    background-color: $forest;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 160px;
    padding: 25px 15px 15px;
    position: relative;
    text-align: center;

    h3 {
      @include header-five;
      color: $nearwhite;
      margin-bottom: 10px;
      position: absolute;
      top: 25px;
    }

    p {
      @include header-three;
      color: $peach;
      margin-top: 25px;
    }
  }
}
