@import "styles/mixins.scss";
@import "styles/variables.scss";

.errorBanner {
  @include header-four;
  background-color: $terracotta;
  border-radius: 15px;
  color: $white;
  margin-bottom: 30px;
  padding: 15px 30px;
  text-align: center;

  &__notError {
    background-color: $peach;
  }
}
