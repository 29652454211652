@import "styles/mixins.scss";
@import "styles/variables.scss";

.profilecard {
  background-color: $white;
  border: 2px solid $peach;
  color: $forest;
  display: block;
  padding: 35px 20px 15px;
  position: relative;
  text-align: left;

  &__image {
    background-color: $peach;
    background-size: cover;
    border: 2px solid $peach;
    border-radius: 50%;
    height: 40px;
    position: absolute;
    right: 10px;
    top: -20px;
    width: 40px;

    svg {
      position: absolute;
      right: -14px;
      top: -30px;
    }

    @include respond() {
      height: 75px;
      right: 20px;
      top: -37.5px;
      width: 75px;
    }
  }

  h4 {
    @include header-three;
    margin-bottom: 10px;
    max-width: 230px;
  }

  p {
    @include body-caption;
    max-width: 230px;
  }

  &__location {
    color: $grey;
  }
}

.profilecardMini {
  background-color: $white;
  border: 2px solid $peach;
  color: $forest;
  display: block;
  padding: 20px 15px 50px;
  position: relative;

  @include respond() {
    padding: 30px 20px 50px;
  }

  &__image {
    background-color: $peach;
    background-size: cover;
    border: 2px solid $peach;
    border-radius: 50%;
    height: 50px;
    position: absolute;
    right: 10px;
    top: -15px;
    width: 50px;

    @include respond() {
      height: 75px;
      top: -21.5px;
      width: 75px;
    }

    svg {
      position: absolute;
      right: -14px;
      top: -30px;
    }
  }

  h4 {
    @include header-three;
    margin-bottom: 10px;
    padding-right: 65px;
  }

  p {
    @include body-caption;
  }

  &__footer {
    align-items: center;
    bottom: 0px;
    color: $grey;
    display: flex;
    height: 35px;
    justify-content: flex-start;
    margin: 0;
    position: absolute;

    @include respond() {
      bottom: 20px;
    }

    &__icons {
      svg {
        margin-right: 18px;
        margin-top: -5px;
      }
    }
  }
}

.profilecardAnswer {
  background-color: $forest;
  color: $nearwhite;
  padding: 30px 30px 50px 30px;
  position: relative;
  text-align: left;

  h4 {
    @include header-three;
    color: $peach;
    padding-bottom: 10px;
  }

  p {
    @include body-caption;
  }

  &__answer {
    background: $white;
    border: 2px solid $peach;
    border-radius: 15px 0 15px 15px;
    color: $forest;
    margin-bottom: -90px;
    padding: 20px;
    position: relative;
    width: calc(100% - 60px);
    // top: calc(100% - 50px);
    z-index: 1;

    &:after {
      border-width: 21px 21px 0 0;
      border-color: rgba(0, 0, 0, 0) $peach;
      border-style: solid;
      content: "";
      display: block;
      height: 0;
      right: -2px;
      position: absolute;
      top: -21px;
      width: 0;
      z-index: 2;
    }

    &:before {
      border-width: 20px 20px 0 0;
      border-color: rgba(0, 0, 0, 0) white;
      border-style: solid;
      content: "";
      display: block;
      height: 0;
      right: 0px;
      position: absolute;
      top: -16px;
      width: 0;
      z-index: 3;
    }
  }
}
