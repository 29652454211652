// breakpoints
$break-tiny: 325px;
$break-mobile: 420px;
$break-xsmall: 580px;
$break-small: 768px;
$break-medium: 1024px;
$break-large: 1220px;
$break-xl: 1600px;
$break-xxl: 1960px;

$mobile: 420px;
$tablet: 768px;
$small-desktop: 1024px;

// Colors
$white: #ffffff;
$nearwhite: #f9f3eb;
$offwhite: #fff7ee;
$black: #262626;
$grey: #8c93a0;
$darkgrey: #696e78;
$lightgrey: #d4dbdb;

// The Lounge colors
$terracotta: #dd5d39;
$peach: #f5c38f;
$sage: #527a64;
$smoke: #b9c2d3;
$lightblue: #e8ebf0;
$sand: #fff7ee;
$sun: #f9be48;
$forest: #284c4a;
$succulent: #b5ba33;
// Transparent Colors
$greyTransparent: rgba(140, 147, 160, 0.4);
$forestTransparent: rgba(40, 76, 74, 0.3);
