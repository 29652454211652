@import "styles/mixins.scss";
@import "styles/variables.scss";

.button {
  a,
  button {
    background: $forest;
    border: 2px solid $forest;
    color: $peach;
    cursor: pointer;
    display: inline-block;
    font-family: "Beatrice-Bold", serif;
    font-size: 18px;
    line-height: 1;
    min-width: 220px;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    transition: border 200ms ease, background 200ms ease, color 200ms ease;

    &:hover {
      background: $peach;
      border: 2px solid rgba(245, 195, 143, 1);
      color: $forest;
    }

    &:disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  &__peach {
    a,
    button {
      background: rgba(245, 195, 143, 0);
      border: 2px solid rgba(245, 195, 143, 1);
      color: $peach;

      &:hover {
        background: $peach;
        color: $forest;
      }
    }
  }

  &__transparent {
    a,
    button {
      background: rgba(245, 195, 143, 0);
      border: 2px solid $forest;
      color: $forest;

      &:hover {
        background: $forest;
        border: 2px solid $forest;
        color: $peach;
      }
    }
  }

  &__googleCal {
    margin-bottom: 20px;
    a {
      line-height: 1.3;
    }
  }

  &__white {
    a,
    button {
      background: rgba(245, 195, 143, 0);
      border: 2px solid $nearwhite;
      color: $nearwhite;

      &:hover {
        background: $peach;
        border: 2px solid $forest;
        color: $forest;
      }
    }
  }

  &__greenWhite {
    a,
    button {
      color: $white;

      &:hover {
        background: $peach;
        border: 2px solid $forest;
        color: $forest;
      }
    }
  }

  &__fullWhite {
    a,
    button {
      background: rgba(255, 255, 255, 1);
      border: 2px solid $white;
      color: $forest;

      &:hover {
        background: $peach;
        border: 2px solid $peach;
        color: $forest;
      }
    }
  }

  &__fullSand {
    a,
    button {
      background: $sand;
      border: 2px solid $sand;
      color: $forest;

      &:hover {
        background: $peach;
        border: 2px solid $peach;
        color: $forest;
      }
    }
  }

  &__fullPeach {
    a,
    button {
      background: $peach;
      border: 2px solid $peach;
      color: $forest;

      &:hover {
        background: $sand;
        border: 2px solid $sand;
        color: $forest;
      }
    }
  }

  &__smaller {
    a,
    button {
      font-size: 15px;
      min-width: auto;
      padding: 10px 20px;
      width: 130px;
    }
  }

  &__center {
    text-align: center;
  }
}

.createBackWrapper {
  @include layout(1080px);
  position: absolute;
  left: 15px;
  top: 30px;

  @include respond() {
    left: auto;
    margin-bottom: -60px;
    margin-top: 120px;
    position: relative;
    top: auto;
    width: 100%;
  }
}

.buttonFilter {
  button {
    background-color: rgba(245, 195, 143, 0);
    border: 2px solid rgba(245, 195, 143, 1);
    border-radius: 15px;
    color: $nearwhite;
    cursor: pointer;
    display: inline-block;
    font-family: "Beatrice-Bold", serif;
    font-size: 15px;
    line-height: 1;
    padding: 13px 20px;
    text-align: center;
    text-decoration: none;
    transition: border 200ms ease, background-color 200ms ease, color 200ms ease;

    &:hover {
      background-color: $sand;
      border: 2px solid rgba(245, 195, 143, 1);
      color: $forest;
    }

    &:disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    svg {
      margin-left: 10px;
      stroke: $peach;
      transition: stroke 200ms ease;
    }
  }

  &__active {
    button {
      background: $peach;
      border: 2px solid $peach;
      color: $forest;

      svg {
        stroke: $forest;
      }
    }
  }

  &__lightBg {
    button {
      border: 2px solid $forest;
      color: $forest;

      svg {
        stroke: $forest;
      }

      &:hover {
        background-color: $peach;
        border: 2px solid $peach;
        color: $forest;
      }
    }
  }
}
