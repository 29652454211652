@import "styles/mixins.scss";
@import "styles/variables.scss";

.header {
  @include gutter();
  @include z2;
  position: absolute;
  top: 10px;
  width: 100%;

  &__wrapper {
    @include layout(1200px);
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    // min-height: 60px;
    padding-bottom: 15px;
    padding-top: 15px;
    position: relative;
    width: 100%;

    &__simple {
      // justify-content: flex-end;
      margin-top: 15px;
    }
  }

  &__join {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    @include respond() {
      > div:last-child {
        margin-left: 20px;
      }
    }
  }

  &__logo {
    &__simple {
      @include respond() {
        align-self: flex-end;
      }
    }

    svg {
      width: 140px;
    }
  }

  &__membership {
    @include respond() {
      svg {
        width: 250px;
      }
    }
  }

  &__menuicon {
    display: none;
    margin-top: -7px;
    width: 20px;

    svg {
      width: 100%;
    }

    @include respond-below() {
      display: block;
    }
  }

  &__back {
    @include header-four;
    cursor: pointer;

    svg {
      margin-right: 10px;
      transition: margin-right 200ms ease;
    }

    &:hover {
      svg {
        margin-right: 13px;
      }
    }

    &__hide {
      visibility: hidden;
    }
  }

  &__progressBar {
    @include z2;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;

    &__item {
      background-color: $forestTransparent;
      flex-basis: 100%;
      height: 9px;

      &__filled {
        background-color: $peach;
      }
    }
  }

  &__join {
    @include respond-below() {
      display: flex;
      flex-direction: column;
      margin-top: -75px;

      a {
        font-size: 26px;
        line-height: 34px;
      }
    }
  }
}

.navigation {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;

  @include respond-below() {
    @include z5;
    background-color: $white;
    display: block;
    right: 0;
    max-width: 500px;
    opacity: 0;
    overflow: scroll;
    height: 100vh;
    position: fixed;
    top: 0;
    transform: translateX(500px);
    transition: transform 200ms ease, opacity 200ms ease;
    width: 100%;
  }

  &__wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    @include respond-below() {
      flex-direction: column;
      justify-content: center;
      padding: 40px 0 80px;
      width: 100%;
    }
  }

  &__open {
    opacity: 1;
    transform: translateX(0);
  }

  &__closeicon {
    display: none;

    @include respond-below() {
      @include z6;
      display: block;
      // right: 23px;
      padding: 5px;
      position: fixed;
      top: 22px;
    }
  }
}

.navItem {
  border-bottom: 5px solid transparent;
  font-family: "Beatrice-Bold", serif;
  font-size: 18px;
  line-height: 1;
  margin: 0 20px;
  padding: 10px 5px;
  transition: border-bottom 200ms ease;

  a {
    color: $forest;
    transition: color 200ms ease;
  }

  &:last-of-type {
    padding-right: 10px;
  }

  &:hover {
    border-bottom: 5px solid $terracotta;
  }

  &__active {
    border-bottom: 5px solid $terracotta;
  }

  &__white {
    @include respond() {
      a {
        color: $nearwhite;
      }
    }
  }

  &__offwhite {
    @include respond() {
      a {
        color: $offwhite;
      }
    }
  }

  @include respond-below() {
    font-size: 26px;
    line-height: 34px;
    margin: 5px 0;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__button {
    border: 2px solid $forest;
    min-width: 150px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
}

.profileNav {
  position: relative;
  text-align: center;

  @include respond() {
    text-align: left;
  }

  &__image {
    background-color: $peach;
    background-size: cover;
    border: 2px solid $peach;
    border-radius: 50%;
    cursor: pointer;
    height: 60px;
    margin-left: 20px;
    transition: border 200ms ease;
    width: 60px;

    @include respond-below() {
      margin: 30px auto;
    }

    &__active {
      border: 4px solid $terracotta;
    }
  }

  &__dropdown {
    @include respond() {
      @include z2;
      background: $white;
      border: 2px solid $forest;
      border-radius: 15px 0 15px 15px;
      opacity: 0;
      max-height: 0;
      padding: 20px;
      position: absolute;
      right: 0;
      top: 90px;
      transition: opacity 200ms ease, max-height 180ms ease;
      visibility: hidden;
      width: 225px;

      &:after {
        border-width: 21px 21px 0 0;
        border-color: rgba(0, 0, 0, 0) $forest;
        border-style: solid;
        content: "";
        display: block;
        height: 0;
        right: -2px;
        position: absolute;
        top: -21px;
        width: 0;
        z-index: 1;
      }

      &:before {
        border-width: 20px 20px 0 0;
        border-color: rgba(0, 0, 0, 0) white;
        border-style: solid;
        content: "";
        display: block;
        height: 0;
        right: 0px;
        position: absolute;
        top: -16px;
        width: 0;
        z-index: 2;
      }
    }

    &__open {
      opacity: 1;
      visibility: visible;
      max-height: 450px;
    }

    a {
      color: $forest;
      display: block;
      font-size: 18px;
      margin-bottom: 15px;

      @include respond-below() {
        font-family: "Beatrice-Bold", serif;
        line-height: 1;
        margin: 0 20px 10px;
        padding: 10px 5px;
      }

      @include respond() {
        line-height: 24px;

        &:last-of-type {
          border-top: 1px solid $smoke;
          margin-bottom: 0;
          padding-top: 10px;
        }
      }

      &:hover {
        font-family: "Beatrice-Bold", serif;
      }
    }
  }
}
