@import "styles/mixins.scss";
@import "styles/variables.scss";

.terms {
  &__header {
    padding-bottom: 20px;
    padding-top: 100px;

    @include respond() {
      padding-top: 160px;
    }

    &__wrapper {
      @include gutter;
      @include layout(570px);

      h1 {
        @include header-one;
        border-bottom: 5px solid $peach;
        padding-bottom: 30px;
        text-align: center;
      }
    }
  }

  &__body {
    @include body-one;
    @include gutter;
    @include layout(630px);
    padding-bottom: 60px;
    padding-top: 30px;

    @include respond() {
      padding-top: 60px;
    }

    strong {
      font-family: "Beatrice-Bold", serif;
    }

    h1 {
      @include header-one;
      margin-bottom: 30px;
      margin-top: 30px;
    }

    h2 {
      @include header-two;
      margin-bottom: 30px;
      margin-top: 30px;
    }

    h3 {
      @include header-three;
      margin-bottom: 30px;
      margin-top: 30px;
    }

    a {
      text-decoration: underline;
    }
  }
}
