@import "styles/mixins.scss";
@import "styles/variables.scss";

.signup {
  &__wrapper {
    @include gutter();
    @include layout(1080px);
    padding-bottom: 40px;
    padding-top: 100px;

    @include respond($tablet) {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 100px;
      padding-top: 180px;
    }
  }

  &__form {
    text-align: center;

    @include respond($tablet) {
      flex-basis: 65%;
      padding-left: 80px;
    }

    form {
      @include layout(450px);
    }

    &__wrapper {
      @include layout(450px);
    }

    h2 {
      @include header-three;
      margin-bottom: 30px;
    }

    &__wrapper {
      @include layout(540px);
    }

    &__passwordReqs {
      text-align: left;
      p,
      li {
        @include body-caption;
        margin-bottom: 2px;
      }
    }
  }

  &__formStripe {
    text-align: center;

    @include respond($tablet) {
      flex-basis: 65%;
      padding-left: 80px;
    }

    form {
      @include layout(550px);
    }

    h2 {
      @include header-three;
      margin-bottom: 30px;
    }
  }

  &__email {
    &__login {
      border-top: 1px solid $forest;
      margin-top: 40px;
      padding-top: 30px;

      h3 {
        @include header-four;
        margin-bottom: 20px;
      }

      p a {
        color: $forest;
        text-decoration: underline;
      }
    }
  }

  &__error {
    position: relative;
    top: -23px;
  }

  &__info {
    h2 {
      @include header-two;
    }

    h3 {
      @include header-three;
    }

    &__cards {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;

      > div {
        flex-basis: 25%;

        img {
          height: auto;
          width: 75px;
        }

        p {
          font-size: 13px;
        }
      }
    }
  }

  &__loginLink {
    @include header-three;
    margin-top: 30px;

    a {
      color: $terracotta;
      text-decoration: underline;
    }
  }

  &__confirm {
    background-color: $white;
    border: 2px solid $peach;
    margin-bottom: 35px;
    padding: 40px 50px;

    &__wrapper {
      @include layout(560px);
      padding-left: 40px;
      text-align: center;
    }

    &__notice {
      background-color: $peach;
      padding: 20px 30px;

      h2 {
        @include header-three;
      }
    }

    &__section {
      border-bottom: 1px solid $grey;
      padding-bottom: 20px;
      padding-top: 20px;
      position: relative;
      text-align: left;

      &:last-of-type {
        border-bottom: none;
      }

      h2 {
        @include mini-label;
        margin-bottom: 10px;
      }

      p {
        margin: 0;
      }
    }

    &__edit {
      cursor: pointer;
      position: absolute;
      right: 25px;
      text-decoration: underline;
      top: 25px;
    }
  }

  &__locations {
    padding-bottom: 30px;
    h2 {
      @include header-three;
      margin-bottom: 30px;
      text-align: center;
    }

    &__labelText {
      @include body-caption;
    }

    &__row {
      display: grid;
      grid-auto-rows: 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      grid-template-columns: repeat(2, 1fr);

      @include respond(600px) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &__alert {
      background-color: $peach;
      border-radius: 15px;
      color: $nearwhite;
      margin: 30px 0 70px 0;
      padding: 20px;
      width: 100%;
    }
  }

  &__productSelect {
    display: grid;
    grid-auto-rows: 1fr;
    grid-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto;
    max-width: 550px;
    padding-bottom: 30px;
    padding-top: 10px;

    &__item {
      align-items: center;
      background-color: $white;
      border: 2px solid $peach;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 165px;
      padding: 15px 20px;
      position: relative;
      transition: background-color 200ms ease;

      span {
        @include header-five;
        background: #d7501c;
        border-radius: 6px;
        color: $nearwhite;
        position: absolute;
        left: -30px;
        padding: 5px 10px;
        top: 0;
        transform: rotate(-30deg);

        @include respond-below() {
          top: -15px;
        }
      }

      h3 {
        @include mini-label;
        border-bottom: 1px solid $grey;
        margin-bottom: 20px;
        padding-bottom: 15px;
        width: 100%;
      }

      h4 {
        @include header-two;
        padding-bottom: 5px;
        padding-top: 5px;
      }

      p {
        @include body-caption;
      }
      &__active {
        background-color: $peach;

        h3 {
          border-bottom: 1px solid $forest;
        }
      }
    }
  }
}

.emailSignup {
  color: $nearwhite;
  text-align: center;

  &__wrapper {
    @include respond(960px) {
      display: flex;
    }
  }

  &__body {
    background-color: $forest;
    min-height: 100vh;

    @include respond(960px) {
      align-items: center;
      display: flex;
      flex-basis: 60%;
      justify-content: center;
      text-align: center;
    }

    &__wrapper {
      @include gutter;
      @include layout(495px);
      width: 100%;
    }

    p {
      @include body-one;

      a {
        color: $nearwhite;
        font-size: 13px;
      }
    }

    form {
      margin-top: 40px;
    }

    button {
      margin-bottom: 25px;
      min-width: 250px;
    }
  }

  &__sidebar {
    flex-basis: 40%;
    position: relative;

    img {
      left: 0;
      position: absolute;
      top: 0;
      height: 100vh;

      @include respond-below(960px) {
        display: none;
      }
    }
  }

  &__subtitle {
    @include body-one;
    margin-bottom: 60px;
  }

  &__login {
    p {
      @include body-small;
      margin-bottom: 5px;

      a {
        @include body-small-link;
      }
    }
  }
}

.marketingPage {
  &__header {
    background-color: $forest;

    &__wrapper {
      @include layout();
      align-items: center;
      color: $peach;
      justify-content: space-between;
      padding: 100px 25px 80px;
      text-align: center;

      @include respond(940px) {
        display: flex;
        padding: 150px 25px 160px 25px;
        text-align: left;
      }
    }

    h1 {
      @include header-zero;
      margin-bottom: 30px;

      @include respond(940px) {
        flex-basis: 50%;
        max-width: 485px;
      }
    }

    p {
      @include body-large;
      color: $sand;
      flex-basis: 50%;
    }
  }

  &__communityCare {
    overflow: hidden;

    &__wrapper {
      @include layout();
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 60px 25px;

      @include respond($tablet) {
        flex-direction: row;
        padding: 30px 25px;
      }
    }

    &__text {
      width: 100%;

      @include respond($tablet) {
        flex-basis: 50%;
        padding-right: 40px;
      }

      h2 {
        @include header-two;
        margin-bottom: 20px;
        max-width: 425px;
      }

      p {
        @include body-large;
      }
    }

    &__image {
      width: 100%;

      @include respond($tablet) {
        flex-basis: 50%;
      }

      img {
        width: 120%;
        position: relative;

        @include respond($tablet) {
          right: -30px;
        }
      }
    }
  }

  &__perks {
    @include gutter;
    @include layout(1110px);

    &__wrapper {
      border: 4px solid $forest;
    }

    &__header {
      background-color: $peach;
      border-bottom: 4px solid $forest;
      padding: 60px 30px 60px 30px;
      text-align: center;

      h2 {
        @include header-one;
        @include layout(650px);
        margin-bottom: 30px;
      }

      p {
        @include body-large;
        @include layout(680px);
        margin-bottom: 30px;
      }
    }

    &__gatherings {
      @include layout(850px);
      border-bottom: 1px solid $grey;
      display: flex;
      flex-direction: column;
      padding: 50px 25px;

      @include respond($tablet) {
        flex-direction: row;
        padding: 70px 25px;
      }

      @include respond(849px) {
        padding: 70px 0;
      }

      > div:first-child {
        @include respond($tablet) {
          padding-right: 40px;
        }
      }

      &__text {
        @include respond($tablet) {
          flex-basis: 50%;
          flex-shrink: 0;
        }

        h3 {
          @include header-two;
          margin-bottom: 10px;
          max-width: 345px;

          @include respond-below($tablet) {
            margin: 0 auto 10px;
          }
        }

        p {
          @include body-one();
          max-width: 345px;

          @include respond-below($tablet) {
            margin: 0 auto;
          }
        }
      }

      &__image {
        position: relative;
        text-align: center;

        @include respond($tablet) {
          flex-basis: 50%;
          flex-shrink: 0;
          text-align: left;
        }

        img {
          margin-bottom: 20px;
          max-width: 345px;
          width: 100%;
        }
      }

      &__eventCard {
        position: relative;
        text-align: center;

        @include respond($tablet) {
          flex-basis: 50%;
          flex-shrink: 0;
        }

        &__image {
          margin-top: 30px;
          max-width: 300px;
          width: 100%;

          @include respond($tablet) {
            bottom: -150px;
            margin: 0;
            position: absolute;
            right: 0;
            width: 300px;
            z-index: 2;
          }
        }

        a {
          @include respond($tablet) {
            position: relative;
            top: -40px;
            width: 350px;
            z-index: 2;
          }
        }
      }

      &__multiImage {
        position: relative;

        @include respond-below($tablet) {
          align-items: center;
          display: flex;
          justify-content: space-between;
        }

        @include respond($tablet) {
          flex-basis: 50%;
          flex-shrink: 0;
        }

        img {
          @include respond($tablet) {
            position: absolute;
            right: 0;
            bottom: -40px;
            width: 250px;
            z-index: 1;

            &:first-of-type {
              bottom: auto;
              left: 0;
              right: auto;
              top: -40px;
              z-index: 2;
            }
          }
        }
      }
    }
  }

  &__footer {
    background-color: $forest;
    text-align: center;

    &__top {
      @include gutter;
      @include layout();
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 60px;
      padding-top: 80px;
      text-align: left;

      @include respond() {
        flex-direction: row;
      }
    }

    &__row {
      flex-basis: 33%;
      padding-bottom: 20px;

      h3 {
        @include body-caption;
        color: $peach;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }

    &__link {
      a {
        @include body-one;
        color: $nearwhite;
      }
    }

    &__social {
      a {
        margin-right: 10px;
      }
    }

    &__subtitle {
      @include body-large;
      color: $nearwhite;
      max-width: 340px;
      padding-bottom: 30px;
    }

    &__logo {
      width: 250px;
    }

    &__bottom {
      border-top: 2px solid $peach;
      color: $nearwhite;
      padding: 20px;
      text-align: center;

      p {
        @include header-five;
        margin-bottom: 0;
      }
    }
  }

  &__message {
    @include gutter;
    @include layout();
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
    padding-top: 60px;

    @include respond(650px) {
      flex-direction: row;
      padding-bottom: 100px;
      padding-top: 100px;
    }

    &__image {
      margin-bottom: 30px;
      text-align: center;

      @include respond(650px) {
        padding-right: 40px;
        text-align: left;
      }

      img {
        max-width: 250px;
      }
    }

    &__text {
      h2 {
        @include header-five;
        color: $grey;
        margin-bottom: 30px;
      }

      h3 {
        @include header-two;
        margin-bottom: 30px;
      }

      p {
        @include body-one;
        max-width: 540px;
      }

      strong {
        font-family: "Beatrice-Bold", sans-serif;
      }
    }
  }

  &__join {
    background-color: $lightblue;

    &__wrapper {
      @include gutter;
      @include layout();
      display: flex;
      flex-direction: column;
      padding-bottom: 60px;

      @include respond($tablet) {
        flex-direction: row;
        padding-bottom: 0;
      }
    }

    &__text {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 80px 0;

      @include respond($tablet) {
        align-items: flex-start;
        flex-basis: 40%;
        flex-shrink: 0;
        padding-right: 40px;
      }

      h2 {
        @include header-one;
        margin-bottom: 50px;
        max-width: 400px;
        text-align: center;

        @include respond($tablet) {
          text-align: left;
        }
      }
    }

    &__images {
      display: flex;

      @include respond($tablet) {
        flex-basis: 50%;
        flex-shrink: 0;
      }

      &__col {
        flex-basis: 50%;
        padding: 5px;

        @include respond($tablet) {
          position: relative;
          top: -60px;

          &:last-of-type {
            bottom: -30px;
            top: auto;
          }
        }

        img {
          height: auto;
          margin-bottom: 10px;
          width: 100%;
        }
      }
    }
  }

  &__footer {
    background-color: $forest;
    min-height: 200px;
  }
}
