@import "styles/mixins.scss";
@import "styles/variables.scss";

.perks {
  @include body-one();
  margin-top: 80px;

  @include respond(768px) {
    margin-top: 180px;
  }

  &__wrapper {
    @include gutter();
    @include layout();
    margin-bottom: 100px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;

    @include respond(768px) {
      flex-wrap: nowrap;
    }

    &__title {
      flex-basis: 100%;
      margin-bottom: 20px;

      @include respond(768px) {
        flex-basis: 50%;
        flex-shrink: 0;
        margin-bottom: 0;
      }

      h1 {
        @include header-one();
        max-width: 500px;
      }
    }

    &__content {
      flex-basis: 100%;

      @include respond(768px) {
        flex-basis: 50%;
        flex-shrink: 0;
        padding-left: 30px;
      }

      p {
        @include body-large();
      }
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 35px;
    margin-top: 60px;

    @include respond(768px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__card {
    background-color: $white;
    border: solid 4px $forest;
    position: relative;
    text-align: center;

    @include respond(500px) {
      padding-bottom: 75px;
    }

    &__image {
      background-position: center;
      background-size: cover;
      height: 250px;
      width: 100%;

      @include respond() {
        height: 400px;
      }
    }

    &__body {
      padding: 30px 25px;

      h2 {
        @include header-three;
        margin-bottom: 25px;
      }
    }

    &__subTitle {
      @include header-five;
      color: $grey;
    }

    &__content {
      @include body-caption;
      @include layout(345px);
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      font-family: "Beatrice-Bold", sans-serif;
      font-size: 18px;
      line-height: 1;
      width: 100%;

      @include respond(500px) {
        bottom: 0;
        flex-direction: row;
        position: absolute;
      }

      &__popup {
        border-top: 4px solid $forest;
        color: $forest;
        cursor: pointer;
        flex-basis: 50%;
        padding: 25px;
        text-align: center;
        transition: background-color 200ms ease;

        @include respond(500px) {
          border-right: 4px solid $forest;
        }

        svg {
          margin-right: 10px;
        }

        &:hover {
          background-color: $peach;
        }
      }

      &__viewSite {
        background-color: $forest;
        border-top: 4px solid $forest;
        color: $peach;
        flex-basis: 50%;
        padding: 25px;
        text-align: center;
        transition: background-color 200ms ease, color 200ms ease;

        svg {
          height: 14px;
          margin-left: 10px;

          path {
            fill: $peach;
            transition: fill 200ms ease;
          }
        }

        &:hover {
          background-color: $peach;
          color: $forest;

          svg path {
            fill: $forest;
          }
        }
      }
    }
  }

  &__modal {
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0px;
    position: fixed;
    top: 0px;
    width: 100%;

    &__body {
      @include z2();
      background-color: $forest;
      color: $white;
      left: 50%;
      max-height: 100vh;
      max-width: 800px;
      overflow-y: scroll;
      padding: 50px 25px;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      @include respond(768px) {
        padding: 100px 25px;
      }
    }

    &__brandLogo {
      margin-bottom: 30px;

      img {
        height: auto;
        width: 245px;
      }
    }

    &__subTitle {
      @include body-small;
      @include layout(375px);
      border-bottom: 2px solid $white;
      font-family: "Beatrice-Bold", serif;
      padding-bottom: 15px;
    }

    &__content {
      @include body-small;
      @include layout(255px);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 20px;
    }

    &__button {
      background-color: $white;
      border: 2px solid $white;
      color: $forest;
      cursor: pointer;
      margin-top: 30px;
      padding: 15px 30px;
      min-width: 260px;
      transition: background-color 200ms ease, color 200ms ease;

      &:hover {
        background-color: $forest;
        color: $white;
      }
    }

    &__cross {
      position: absolute;
      right: 25px;
      top: 25px;

      @include respond(768px) {
        right: 30px;
        top: 30px;
      }
    }

    &__opacityLayer {
      @include z1();
      background-color: $grey;
      height: 100vh;
      left: 0;
      opacity: 0.7;
      position: absolute;
      top: 0;
      width: 100vw;
    }
  }
}
