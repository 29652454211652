@import "styles/mixins.scss";
@import "styles/variables.scss";

.members {
  &__wrapper {
    background-color: $forest;
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    &__wrapper {
      @include layout();
      align-items: flex-end;
      justify-content: space-between;
      padding: 100px 25px 25px;
      position: relative;
      text-align: left;

      @include respond(940px) {
        display: flex;
        padding: 170px 25px 75px 25px;
      }

      img {
        bottom: -90px;
        left: 60%;
        position: absolute;
        transform: translateX(-60%) scaleX(-1);
        width: 300px;

        @include respond() {
          bottom: -60px;
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    h1 {
      @include header-one;
      margin-bottom: 30px;

      @include respond(940px) {
        flex-basis: 55%;
        margin-bottom: 0;
        max-width: 485px;
      }
    }

    p {
      @include body-large;
      flex-basis: 45%;
    }
  }

  &__suggested {
    @include layout();
    padding: 25px;
  }

  &__paginationWrapper {
    background-color: $forest;
    border-bottom: 2px solid $peach;
  }

  &__top {
    @include gutter;
    @include layout();
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 40px;
    padding-top: 60px;

    @include respond-below() {
      flex-direction: column;
    }

    h2 {
      @include header-two();
      color: $sand;

      @include respond-below() {
        padding-bottom: 30px;
      }
    }
  }

  &__suggested {
    @include gutter;
    @include layout;
    padding-bottom: 30px;
    padding-top: 20px;
    position: relative;
  }

  &__filter {
    overflow: hidden;
    &__mobile {
      display: none;
      @include respond-below() {
        display: flex;
        justify-content: center;
      }
    }

    &__wrapper {
      @include gutter;
      @include layout();
      display: flex;
      flex-wrap: wrap;

      @include respond-below($tablet) {
        background-color: $white;

        height: 100vh;
        overflow: scroll;
        padding: 60px 0;
        position: fixed;
        top: 0;
        transform: translateY(110vh);
        transition: transform 400ms ease;
        z-index: 300;
      }

      &__open {
        @include respond-below($tablet) {
          transform: translateY(0);
        }
      }
    }

    &__mobileClose {
      display: none;
      @include respond-below($tablet) {
        display: block;
        position: absolute;
        padding: 10px;
        right: 10px;
        top: 10px;
        z-index: 400;
      }
    }
  }

  &__display {
    @include gutter;
    @include layout();
    display: grid;
    grid-auto-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 50px;
    grid-template-columns: repeat(1, 1fr);
    justify-content: space-between;
    position: relative;
    padding-bottom: 80px;
    padding-top: 30px;

    @include respond($break-mobile) {
      padding-top: 70px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond() {
      grid-column-gap: 35px;
      grid-row-gap: 60px;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.sorting {
  position: relative;
  &__toggle {
    border-bottom: 5px solid $terracotta;
    color: $nearwhite;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    padding: 0 2px 8px;

    span {
      font-family: "Beatrice-Bold", serif;
      font-size: 13px;
      letter-spacing: 2px;
      margin-right: 5px;
      text-transform: uppercase;
    }

    svg {
      margin-left: 15px;
      width: 25px;

      path {
        stroke: $peach;
      }
    }
  }

  &__dropdown {
    background-color: $white;
    border: 2px solid $forest;
    border-radius: 15px;
    display: none;
    padding: 22px 30px;
    position: absolute;
    right: 0;
    top: 45px;
    width: 265px;
    z-index: 100;

    &__open {
      display: block;
    }

    &__item {
      display: block;
      font-size: 18px;
      line-height: 32px;
      padding: 5px 0;

      &:hover {
        font-family: "Beatrice-Bold", serif;
      }

      &__active {
        font-family: "Beatrice-Bold", serif;
      }
    }
  }
}
