@import "styles/mixins.scss";
@import "styles/variables.scss";

a.membersGroupCard {
  background: linear-gradient(0deg, #fff7ee, #fff7ee), #ffffff;
  border: 2px solid #f4bf84;
  box-sizing: border-box;
  border-radius: 15px;
  display: block;
  margin-bottom: 20px;
  max-width: 250px;
  padding: 24px 20px;
  width: 100%;

  span {
    display: block;
  }

  span:first-child {
    color: $forest;
    @include header-three;
    margin-bottom: 25px;
  }
  span:last-child {
    color: $darkgrey;
    font-size: 13px;
    line-height: 20px;
  }
}

.membersGroupCardWrapper {
}
