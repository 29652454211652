@import "styles/mixins.scss";
@import "styles/variables.scss";

.hostEvents {
  @include header-top-padding();
  padding-bottom: 60px;

  &__wrapper {
    @include gutter();
    @include layout();
  }

  &__top {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 60px;

    @include respond() {
      flex-direction: row;
    }

    h1 {
      @include header-one;
      flex-basis: 65%;
      flex-shrink: 0;
      text-align: center;

      @include respond-below() {
        margin-bottom: 30px;
      }
    }
  }

  &__events {
    padding-bottom: 60px;
    padding-top: 60px;
  }

  &__noEvents {
    padding: 60px 0;
    text-align: center;

    h2 {
      @include header-two;
    }
  }

  &__event {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond() {
      flex-direction: row;
    }

    &:first-of-type {
      margin-top: 40px;
    }

    > a {
      margin-bottom: 10px;

      @include respond() {
        flex-basis: 80%;
        margin-bottom: 60px;
      }
    }

    &__edit {
      @include respond() {
        flex-basis: 20%;
        padding-left: 40px;
      }

      p,
      a {
        @include body-small;
        align-items: center;
        color: $forest;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        transition: color 200ms ease;

        svg {
          height: 21px;
          margin-right: 10px;

          path,
          circle {
            stroke: $forest;
            transition: fill 200ms ease;
          }
        }

        &:hover {
          color: $terracotta;

          svg path,
          svg circle {
            stroke: $terracotta;
          }
        }
      }
    }
  }

  &__progress {
    background-color: $forestTransparent;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 10px;
    justify-content: flex-start;
    max-width: 100px;
    position: relative;
    width: 100%;

    &__item {
      background-color: $forestTransparent;
      flex-basis: 100%;
      height: 9px;

      &__filled {
        background-color: $forest;
      }
    }
  }
}

.createGathering {
  @include header-top-padding();
  padding-bottom: 60px;

  &__wrapper {
    @include gutter;
    @include layout();
    display: flex;
    flex-direction: column-reverse;
    padding-top: 20px;

    @include respond($tablet) {
      flex-direction: row;
      padding-top: 80px;
    }
  }

  &__image {
    @include respond($tablet) {
      flex-basis: 50%;
    }

    img {
      height: auto;
      margin: 0 auto;
      max-width: 440px;
      width: 100%;
    }
  }

  &__body {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 30px;
    text-align: center;

    @include respond($tablet) {
      flex-basis: 50%;
    }

    h1 {
      @include header-two;
      margin-bottom: 20px;
    }

    p {
      @include body-large;
      @include layout(445px);
      margin-bottom: 40px;
    }
  }
}

.hosting {
  @include header-top-padding();
  @include gutter;
  padding-bottom: 80px;

  &__wrapper {
    @include gutter;
    @include layout(1080px);
    border: 2px solid $forestTransparent;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 20px;
    padding-top: 20px;

    @include respond() {
      flex-direction: row;
      padding-bottom: 80px;
      padding-top: 80px;
    }
  }

  &__image {
    padding-top: 60px;

    @include respond() {
      flex-basis: 50%;
      padding-top: 0;
    }

    img {
      height: auto;
      margin: 0 auto;
      max-width: 330px;
      width: 100%;
    }
  }

  &__body {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @include respond() {
      flex-basis: 50%;
    }

    h1 {
      @include header-two;
      margin-bottom: 20px;
    }

    p {
      @include body-large;
      @include layout(445px);
      margin-bottom: 40px;
    }
  }
}

.cancel {
  @include z4;
  align-items: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  overflow: scroll;
  position: fixed;
  top: 0;
  width: 100vw;

  &__body {
    @include layout(615px);
    background-color: $sand;
    position: relative;
    width: 100%;
    @include z5;

    h3 {
      @include header-three;
    }

    p {
      @include body-two-bold;
      color: $terracotta;
    }

    &__wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 30px 25px 60px;
      text-align: center;

      @include respond() {
        padding: 30px 40px;
      }
    }
  }

  &__close {
    cursor: pointer;
    right: 10px;
    padding: 5px;
    position: absolute;
    top: 10px;

    @include respond() {
      right: 20px;
    }
  }

  &__buttons {
    align-items: center;
    display: flex;
    justify-content: center;

    button {
      margin: 20px 20px;
    }
  }
}
