@import "styles/mixins.scss";
@import "styles/variables.scss";

.loungeLogo {
  height: auto;
  width: 115px;

  @include respond-below() {
    left: 50%;
    position: absolute;
    text-align: center;
    top: 0;
    transform: translateX(-50%);
  }

  svg {
    fill: $terracotta;
    width: 100%;

    @include respond-below() {
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }
  }

  &__large {
    width: 100%;
    margin: 0 auto;
    max-width: 250px;

    svg {
      fill: $peach;
      width: 100%;
    }
  }

  &__full {
    width: 100%;
    max-width: 250px;

    @include respond-below() {
      max-width: 175px;
    }

    svg {
      fill: $peach;
      width: 100%;
    }
  }
}
