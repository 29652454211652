@import "styles/mixins.scss";
@import "styles/variables.scss";

.filter {
  margin-bottom: 15px;
  margin-right: 10px;

  @include respond-below($tablet) {
    margin: 0;
    width: 100%;

    button {
      background-color: transparent;
      border: 0;
      color: $forest;
      margin: 0;
      padding: 0 20px;
      pointer-events: none;
    }

    svg {
      display: none;
    }
  }
}

.filterModal {
  background-color: $white;
  border: 2px $black solid;
  border-radius: 15px;
  display: none;
  flex-direction: column;
  // flex-wrap: wrap;
  margin-top: 10px;
  max-width: 550px;
  padding: 30px 20px 20px;
  position: absolute;
  width: 100%;
  z-index: 99;

  &__open {
    display: flex;
  }

  @include respond-below() {
    // max-width: 300px;
    // left: 50%;
    // transform: translate(-50%, 0%);
    border: 0;
    border-radius: 0;
    display: block;
    position: relative;
  }

  &__bottom {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    width: 100%;
  }

  &__box {
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid $forest;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    height: 18px;
    margin-right: 15px;
    transition: background-color 200ms ease;
    width: 18px;

    svg {
      fill: $peach;
      opacity: 0;
      transition: opacity 200ms ease;
    }

    &__active {
      background-color: $forest;

      svg {
        opacity: 1;
      }
    }
  }

  &__clear {
    border-bottom: 2px solid $forestTransparent;
    color: $forestTransparent;
    cursor: pointer;
    font-family: "Beatrice-Bold", serif;
    height: 22px;
    margin-left: 10px;
    transition: color 200ms ease;

    &:hover {
      border-bottom: 2px solid $forest;
      color: $forest;
    }
  }

  &__entries {
    align-items: flex-start;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: 10px;

    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 20px;
    width: 100%;

    &__entry {
      cursor: pointer;
      display: flex;
      // flex-basis: 50%;
      // margin-bottom: 20px;
      // padding-right: 5px;
      // width: calc(50% - 20px);

      @include respond-below() {
        font-size: 12px;
        // width: calc(100% - 40px);
      }

      &__active {
        font-family: "Beatrice-Bold", serif;
      }
    }
  }

  &__greyLine {
    border: 1px solid $grey;
    margin-left: -20px;
    position: relative;
    width: calc(100% + 40px);

    @include respond-below($tablet) {
      display: none;
    }
  }
}
