@import "styles/mixins.scss";
@import "styles/variables.scss";

.sideBanner {
  padding-bottom: 30px;
  // position: relative;
  text-align: center;
  top: 50px;
  width: 100%;

  @include respond() {
    flex-basis: 30%;
    flex-shrink: 0;
    padding-right: 30px;
    position: sticky;

    &:after {
      // FROGS: url imports node-sass
      // content: url("icons/pencil-scribble.png");
      position: absolute;
      right: -30px;
      top: -50px;
    }
  }

  &__wrapper {
    max-width: 255px;
    position: relative;

    @include respond-below() {
      margin: 0 auto;
    }
  }

  h1 {
    @include header-two;
    margin-bottom: 20px;
    text-align: left;
  }

  p {
    @include respond() {
      max-width: 255px;
      text-align: left;
    }

    a,
    strong {
      font-weight: bolder;
    }

    a {
      text-decoration: underline;
    }
  }

  img {
    @include layout(250px);
    margin-bottom: 30px;
    margin-top: 30px;
    width: 100%;
  }

  &__imageUpload {
    h1 {
      margin-bottom: 0;
    }
  }

  &__cmsImage {
    width: 100%;
  }
}
