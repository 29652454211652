@import "styles/mixins.scss";
@import "styles/variables.scss";

.profile {
  @include header-top-padding;

  &__wrapper {
    @include gutter();
    @include layout();
    padding-bottom: 60px;
    padding-top: 60px;

    @include respond() {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__body {
    text-align: center;

    @include respond() {
      flex-basis: 70%;
      padding-left: 100px;
    }

    h3 {
      @include header-three;
      margin-bottom: 30px;
      text-align: center;
    }

    &__section {
      @include layout(450px);
      padding-bottom: 30px;

      > p {
        @include body-caption;
        @include layout(415px);
        margin-bottom: 20px;
      }

      &__row {
        display: grid;
        grid-auto-rows: 1fr;
        grid-gap: 10px;
        grid-template-columns: repeat(1, 1fr);
        padding-bottom: 20px;

        @include respond(600px) {
          grid-template-columns: repeat(3, 1fr);
        }
      }

      &__rowTwo {
        display: grid;
        grid-auto-rows: 1fr;
        grid-gap: 10px;
        grid-template-columns: repeat(1, 1fr);
        padding-bottom: 20px;

        @include respond(600px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }

  &__address {
    @include body-one;
    @include layout(455px);
    background-color: $peach;
    margin-bottom: 30px;
    padding: 30px;
    text-align: center;

    &__title {
      @include layout(455px);
    }

    &__help {
      margin-top: 50px;

      a {
        color: $forest;
        text-decoration: underline;
      }
    }
  }
}
