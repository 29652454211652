//--- Media Query Mixins ---//
@mixin respond($breakpoint: 768px) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin respond-below($breakpoint: 767px) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

//--- Layout Mixins ---//
@mixin layout($width: 1170px) {
  margin: 0 auto;
  max-width: $width;
}

@mixin gutter($gutter: 25px) {
  padding-left: $gutter;
  padding-right: $gutter;
}

@mixin vertical-spacing($distance: 60px, $mobiledistance: 30px) {
  padding-bottom: $mobiledistance;
  padding-top: $mobiledistance;

  @include respond() {
    padding-bottom: $distance;
    padding-top: $distance;
  }
}

@mixin header-top-padding($distance: 110px, $mobiledistance: 70px) {
  padding-top: $mobiledistance;

  @include respond() {
    padding-top: $distance;
  }
}

//--- Typography Mixins ---//
@mixin header-one-display() {
  font-family: "BeatriceDisplay-ExtraBold", serif;
  font-size: 48px;
  font-weight: normal;
  line-height: 54px;

  @include respond(710px) {
    font-size: 90px;
    line-height: 94px;
  }

  @include respond() {
    font-size: 130px;
    line-height: 120px;
  }
}

@mixin header-zero() {
  font-family: "Beatrice-ExtraBold", serif;
  font-size: 48px;
  font-weight: normal;
  line-height: 60px;

  @include respond() {
    font-size: 90px;
    line-height: 94px;
  }
}

@mixin header-one() {
  font-family: "Beatrice-ExtraBold", serif;
  font-size: 40px;
  font-weight: normal;
  line-height: 48px;

  @include respond() {
    font-size: 60px;
    line-height: 64px;
  }
}

@mixin header-two() {
  font-family: "Beatrice-ExtraBold", serif;
  font-size: 32px;
  font-weight: normal;
  line-height: 38px;

  @include respond() {
    font-size: 40px;
    line-height: 48px;
  }
}

@mixin header-three() {
  font-family: "Beatrice-Bold", serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;

  @include respond() {
    font-size: 22px;
    line-height: 26px;
  }
}

@mixin header-four() {
  font-family: "Beatrice-Bold", serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;

  @include respond() {
    font-size: 18px;
    line-height: 24px;
  }
}

@mixin header-five() {
  font-family: "Beatrice-Bold", serif;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 2px;
  line-height: 18px;
  text-transform: uppercase;

  @include respond() {
    font-size: 13px;
    line-height: 20px;
  }
}

@mixin body-one() {
  font-size: 14px;
  line-height: 24px;

  @include respond() {
    font-size: 18px;
    line-height: 32px;
  }
}

@mixin body-two-bold() {
  font-family: "Beatrice-Bold", serif;
  font-size: 13px;
  line-height: 18px;

  @include respond() {
    font-size: 15px;
    line-height: 22px;
  }
}

@mixin body-caption() {
  font-size: 13px;
  line-height: 21px;
}

@mixin body-small() {
  font-size: 13px;
  line-height: 18px;

  @include respond() {
    font-size: 15px;
    line-height: 22px;
  }
}

@mixin body-large() {
  font-size: 18px;
  line-height: 30px;

  @include respond() {
    font-size: 24px;
    line-height: 36px;
  }
}

@mixin body-small-link() {
  font-family: "Beatrice-Bold", serif;
  font-size: 13px;
  line-height: 21px;
  text-decoration: underline;

  @include respond() {
    font-size: 15px;
    line-height: 26px;
  }
}

@mixin mini-label() {
  font-family: "Beatrice-Bold", sans-serif;
  font-size: 11px;
  font-weight: normal;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
}

@mixin regular-label() {
  font-family: "Beatrice-Bold", sans-serif;
  font-size: 11px;
  font-weight: normal;
  letter-spacing: 2px;
  line-height: 1;
  text-transform: uppercase;

  @include respond() {
    font-size: 13px;
  }
}

@mixin number() {
  font-family: "Beatrice-ExtraBold", serif;
  font-size: 36px;
  letter-spacing: -2px;
  line-height: 42px;

  @include respond() {
    font-size: 48px;
    line-height: 52px;
  }
}

//--- Z-Index Mixins
// Used to ensure that items are layered correctly
@mixin z1() {
  z-index: 100;
}
@mixin z2() {
  z-index: 200;
}
@mixin z3() {
  z-index: 300;
}
@mixin z4() {
  z-index: 400;
}
@mixin z5() {
  z-index: 500;
}
@mixin z6() {
  z-index: 600;
}
