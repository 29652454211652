@import "styles/mixins.scss";
@import "styles/variables.scss";

$navRowColumnBreak: $break-xsmall;

.cancelledPage {
  @include gutter;
  @include header-top-padding;
  @include layout(640px);
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;

  h2 {
    @include header-two;
    padding-bottom: 20px;
  }

  h3 {
    @include header-three;
    padding-bottom: 20px;
  }

  h4 {
    @include body-one;
    font-weight: normal;
    padding-bottom: 20px;
  }

  .form + .form {
    border-top: 1px solid $forestTransparent;
    margin-top: 36px;
    padding-top: 60px;
  }

  .form button {
    position: relative;
    top: -26px;
  }

  section {
    margin: 0;
    padding-bottom: 40px;
    max-width: 100%;
  }

  &__body {
    flex-basis: 65%;
    padding-top: 20px;

    @include respond($navRowColumnBreak) {
      padding-left: 40px;
    }

    &__text {
      @include body-small;

      a {
        color: $forest;
        font-family: "Beatrice-Bold", serif;
        text-decoration: underline;
      }
    }

    &__legal {
      p {
        @include body-one;
      }
    }
  }
}

.hideSmall {
  @include respond-below($break-mobile) {
    display: none;
  }
}

.stripeForm {
  button {
    margin-top: 40px;
  }
}
