@import "styles/mixins.scss";
@import "styles/variables.scss";

.admin {
  @include layout();
  @include gutter();
  margin-top: 160px;

  h1 {
    @include header-one();
    padding-bottom: 30px;
  }

  &__row {
    display: grid;
    grid-auto-rows: 1fr;
    grid-gap: 10px;
    grid-template-columns: repeat(6, 1fr);

    &__top {
      margin-top: 30px;

      p {
        @include mini-label;
      }
    }

    &__user {
      @include body-small-link;
    }
  }

  a {
    color: $forest;
    cursor: pointer;
    text-decoration: underline;
  }
}
